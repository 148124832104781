.b-button {
    display: inline-flex;
    //border: none;
    outline: none;
    cursor: pointer;
    font-weight: 300;
    padding: 14px 32px 16px;
	border-radius: 30px;
    @include fz(17px, 20px);
    text-align: center;
    justify-content: center;
	border: 1px solid #3C70DE;
	color: #FFFFFF;
    background-color:  #3C70DE;
	appearance: none;
	box-shadow: 0 0 20px rgba(60, 112, 222, 0.583642);


	&[type="submit"] {
		margin-top: 15px;
		padding: 10px 30px;

		@include mobile480 {
			margin-top: 0;
		}
	}

	//@include sm {
	//	padding: 16px 36px;
	//}


    & * {
        @include fz(20px, 16px);
    }
    & i {
        margin-right: 15px;
    }
    @include hover {
	    color: #3C70DE;
	    background-color: #FFFFFF;
    }
}

.b-button_hidden {
	display: none;
}

.b-button_with-border {
	background-color: transparent;
	color: #3C70DE;
	border: 1px solid #3C70DE;
}

.b-button_border {
	border: 1px solid #3C70DE;
}

.b-button_orange {
	border: 1px solid $orangeColor;
	color: $orangeColor;
	padding: 17px 31px 17px;
	max-height: 50px;
	@include fz(13px, 14px);
	box-shadow: none;
	border-radius: 30px;
	background-color: #fff;
	//letter-spacing: 0.09em;

	&_full {
		background-color: $orangeColor;
		border: 1px solid $orangeColor;
		min-width: 158px;
		padding: 8px 31px 10px 33px;
		color: #fff;
		box-shadow: 0 0 20px rgba(255, 70, 0, 0.360989);

		&:hover {
			color: $orangeColor;
		}
	}

	&_product {
		padding: 17px 26px 17px;
		border-radius: 30px;
		max-height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: max-content;
		//letter-spacing: 1px;
		@include fz(16px, 20px);
	}

	@include hover {
		background-color: #FF4600;
		color: #FFFFFF;
	}
}

.b-button_filter {
	margin: 0 auto;
	width: auto;
	display: inline-flex;

	&:active {
		color: #fff;
		background-color: #497FF3;
	}
	> * {
		pointer-events: none;
	}

	.b-sidebar__title {
		margin: 0;
	}

	&:after {
		display: none;
	}
}

.b-button_orange_header{
	padding: 10px 31px 10px;
	position: relative;
	bottom: 3px;
	@include fz(14px, 17px);

	@include sm850 {
		bottom: 0;
	}

}

.b-button_with-arrow {
	position: relative;
	width: auto;
	padding: 12px 72px 16px 33px;

	@include sm {
		padding: 15px 55px 15px 20px;

	}


	&:after {
		content: '';
		top: 50%;
		transform: translateY(-50%);
		left: auto;
		right: -1px;
		position: absolute;
		width: 50px;
		height: 100%;
		background: #497FF3 url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC41Njg4OCAwLjE2MDg0NEM4LjMzNTgzIC0wLjA2MTExNDggNy45NjYyOSAtMC4wNTIzNjQ4IDcuNzQ0MzMgMC4xODA5NjlDNy41MjIwOCAwLjQxNDMwMiA3LjUzMTQyIDAuNzgzNTUyIDcuNzY0NDYgMS4wMDU4TDEyLjIzOTIgNS4yNDk4NEgwLjU4MzMzM0MwLjI2MTMzMyA1LjI0OTg0IDAgNS41MTExOCAwIDUuODMzMThDMCA2LjE1NTE4IDAuMjYxMzMzIDYuNDE2NTEgMC41ODMzMzMgNi40MTY1MUgxMi4yMjA1TDcuNzY0NDYgMTAuNjYwOEM3LjUzMTEzIDEwLjg4MzEgNy41MjIwOCAxMS4yNTIzIDcuNzQ0MzMgMTEuNDg1N0M3Ljg1ODk2IDExLjYwNTggOC4wMTI2NyAxMS42NjY1IDguMTY2NjcgMTEuNjY2NUM4LjMxMTMzIDExLjY2NjUgOC40NTYgMTEuNjEzMSA4LjU2ODg4IDExLjUwNTVMMTMuNjU4MiA2LjY1ODAxQzEzLjg3ODcgNi40Mzc4IDE0IDYuMTQ0OTcgMTQgNS44MzMxOEMxNCA1LjUyMTM5IDEzLjg3ODcgNS4yMjg1NSAxMy42NDggNC45OTg0M0w4LjU2ODg4IDAuMTYwODQ0WiIgZmlsbD0id2hpdGUiLz48L3N2Zz4=) center center no-repeat;
		border-radius: 25px;


		@include sm {
			width: 48px;
			height: 48px;
		}

	}
}

.b-button_with-arrow_header {
	margin-right: auto;
	margin-left: 30px;

	@include sm {
		margin-left: 0;
		margin-right: auto;
	}
}

.b-button_small {
    padding: 12px 30px;
    @include fz(12px, 16px);
    & * {
        @include fz(16px, 16px);
    }
}

.b-button_anim {
    animation: button_drag 0.6s ease-in-out;
}

@keyframes button_drag {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba($not-grad, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba($not-grad, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba($not-grad, 0);
    }
}

.b-button_disabled {
    cursor: not-allowed !important;
}

.b-button_upper {
    text-transform: uppercase;
}

.b-button_min-180 {
    min-width: 180px !important;
}

.b-button_min-130 {
    min-width: 130px !important;
}

.b-button_radius {
    border-radius: 5px;
}

.b-button_w-100 {
    width: 100%;
}

.b-button_to_order {
	min-width: 155px;
}

.b-button_link {
	background-color: transparent;
	border: none;
	font-style: normal;
	font-weight: normal;
	box-shadow: none;
	@include fz(16px, 19px);
	color: #3C70DE;

	&:hover {
		background-color: transparent;
		color: #FF4602;
	}
}

.b-button_grad {
    // background: linear-gradient(99.01deg, darken(#3478dd, 15), #3478dd, #7939e7);
    background-color: $grad;
    // background-size: 200% 100%;
    // background-position: -100% 0;
    // 
    // background: linear-gradient(99.01deg, #3478dd, #7939e7);
    // background-size: 100% 100%;
    @include hover {
        // background-size: 200% 100%;
        // background-size: 200% 100%;
        // background-position: -200% 0;
        background-color: darken($grad, 10);
    }
    //min-width: 220px;
	max-height: 50px;
	display: flex;
	align-items: center;
	border-radius: 30px;
}

.b-button_color-light {
    color: $lightColor;
}

.b-button_color-dark {
    color: $darkColor;
}

.b-button_full {
    width: 100%;
}

.b-button_transparent {
    background-color: transparent;
}

.b-button_border-light {
    border: 1px solid $lightColor;
}

.b-button_white {
	border: 1px solid #FFFFFF;
	background-color: #FFFFFF;
	color: #3C70DE;
	padding: 14px 32px 16px;
	font-size: 15px;
	line-height: 17px;
	font-weight: 500;
	box-shadow: none;


	&_border{
		border: 1px solid #3C70DE;
		padding: 11px 22px 11px;
	}

	@media (hover: hover) {
		@include hover {
			background-color: #3C70DE;
			color: #FFFFFF;
		}
	}

}


.b-content-table {
	table tbody tr {
		//display: flex;
		padding: 8px 12px;
	}
	table tbody tr td {
		width: auto !important;
		height: auto !important;
		//display: flex;
		padding: 8px 12px;
		align-items: center;

		strong {
			display: inline-block;
			margin-right: 5px;
		}
	}
}

.b-button_details {
	padding: 12px 35px 12px;
}


.b-button_all-contacts {
	padding: 10px 28px 10px;
	@include fz(14px, 17px);
}

.b-button_right_auto {
	margin-right: auto;
}

.b-button_questionnaire {
	padding: 14px 28px 16px 54px;
	@include fz(17px, 20px);
	position: relative;

	&:before {
		content : '';
		display :block;
		width: 16px;
		height: 18px;
		position: absolute;
		top: 50%;
		left: 22px;
		transform: translateY(-50%);
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDEzLjczOThMMy4xNzYyMSA3LjUzNzc4SDUuODY2NjdWMEgxMC4xMzMzVjcuNTM3NzhIMTIuODIzOEw4IDEzLjczOThaTTUuMzU3MTIgOC42MDQ0NEw4IDEyLjAwMjRMMTAuNjQyOCA4LjYwNDQ0SDkuMDY2NjdWMS4wNjY2N0g2LjkzMzMzVjguNjA0NDRINS4zNTcxMlpNMTIuOCAxMS44MDQ0SDE2VjE4LjIwNDRIMFYxMS44MDQ0SDMuMlYxNS4wMDQ0SDEyLjhWMTEuODA0NFpNMS4wNjY2NyAxNy4xMzc4SDE0LjkzMzNWMTIuODcxMUgxMy44NjY3VjE2LjA3MTFIMi4xMzMzM1YxMi44NzExSDEuMDY2NjdWMTcuMTM3OFoiIGZpbGw9IiNGRjQ2MDAiLz4KPC9zdmc+Cg==);
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	&:hover {
		&:before {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDEzLjczOThMMy4xNzYyMSA3LjUzNzc4SDUuODY2NjdWMEgxMC4xMzMzVjcuNTM3NzhIMTIuODIzOEw4IDEzLjczOThaTTUuMzU3MTIgOC42MDQ0NEw4IDEyLjAwMjRMMTAuNjQyOCA4LjYwNDQ0SDkuMDY2NjdWMS4wNjY2N0g2LjkzMzMzVjguNjA0NDRINS4zNTcxMlpNMTIuOCAxMS44MDQ0SDE2VjE4LjIwNDRIMFYxMS44MDQ0SDMuMlYxNS4wMDQ0SDEyLjhWMTEuODA0NFpNMS4wNjY2NyAxNy4xMzc4SDE0LjkzMzNWMTIuODcxMUgxMy44NjY3VjE2LjA3MTFIMi4xMzMzM1YxMi44NzExSDEuMDY2NjdWMTcuMTM3OFoiIGZpbGw9IiNGRkZGRkYiLz4KPC9zdmc+Cg==);
		}

	}
}

.b-button_social {
	background-color: rgba(60, 112, 222, 0.8);
	width: 42px;
	height: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: #fff;
	font-size: 21px;
}

.b-button_social_in-form {
	@extend .b-button_social;
}

.b-button_single_offers {
	padding: 13px 35px;
	font-weight: 300;
	@include fz(17px, 20px);
	margin-top: 38px;
}

.b-button_offers_see_all {
	margin-top: 48px;
}
