.b-manager-block {
    width: 100%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    overflow: hidden;
}

.b-manager-block__header {
    position: relative;
    height: 160px;
}

.b-manager-block__image {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 130px 130px;
    border-bottom-right-radius: 130px 130px;
    overflow: hidden;
    &:after {
        position: absolute;
        content: '';
        left: 0;
        top: -100%;
        width: 100%;
        height: 200%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 26.53%);
    }
}

.b-manager-block__image-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.b-manager-block__text {
    z-index: 2;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 20px;
    left: 0;
    color: $lightColor;
    font-size: 14px;
    font-weight: 500;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.b-manager-block__footer {
    padding: 10px;
}

.b-manager-block__button {
    width: 100%;
    min-width: 0 !important;
}