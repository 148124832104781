.b-page {
    min-width: 320px;
    position: relative;
}

.b-page__sidebar {}

.b-page__header {}

.b-page__main {
	position: relative;
	z-index: 1;
}



.b-page__footer {}