.b-tabs {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: -10px;
    margin-top: -10px;
    @include flex-colm;
}

.b-tab {
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
}

.b-tab__header {
    position: relative;
    cursor: pointer;
    padding-bottom: 6px;
    user-select: none;
    width: 100%;
    @include flex-row;
    &.active {
        .b-tab__icon i {
            transform: rotate(-90deg);
        }
    }
}

.b-tab__title {
    @include fz(16px, 18px);
    // font-weight: 700;
    font-weight: 600;
}

.b-tab__icon {
    margin-left: auto;
    margin-right: 3px;
    pointer-events: none;
    @include fz(12px, 12px);
    & i {
        transition: $transition;
        transform: rotate(90deg);
    }
}

.b-tab__header_italic {
    font-style: italic;
}

.b-tab__header_border-bottom {
    border-bottom: 1px solid $darkColor;
}

.b-tab__content {
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
}

.b-tab__content_italic {
    font-style: italic;
}

.b-tab__content p {
    @include inh;
}

.tab-box {
	margin-bottom: 50px;
	padding: 0 13px;

	@include mobile {
		padding: 0;
		margin-bottom: 15px;
	}


	&__buttons {
		display: flex;
		margin-bottom: 35px;
		padding: 9px 29px 9px 33px;

		@include lg {
			flex-wrap: wrap;
			margin-bottom: 20px;
		}

		@include sm {
			padding: 0;
		}
	}

	&__button {

		&:not(:last-child) {
			margin-right: 14px;
		}

		@include lg {
			margin-bottom: 15px;
		}

		&_4 {
			display: none;
		}

		&_5 {
			display: none;
		}

		@include laptop {
			padding: 10px 20px;
		}

		&.active {
			background: rgba(255, 70, 0, 0.17);
			color: rgba(19, 17, 28, 0.68);
			border: 1px solid transparent;
		}
	}

	&__content {
		display: flex;
		position: relative;
		flex-wrap: wrap;
	}

	&__content-item {
		width: 90%;
		margin: 0;
		column-count: 2;
		column-gap: 20px;
		display: none;
		padding-top: 2px;

		@include xs {
			column-count: 1;
			column-gap: 0;
		}

		&.active {
			display: block;
		}
	}

	&__content-item-post {

	}
}