.b-title {
    font-weight: 600;
    @include h_inh();
}

.b-title_upper {
    text-transform: uppercase;
}

.b-title_light {
    color: $lightColor;
}

.b-title_dark {
    color: #000;
}

.b-title_big {
    @include fz(35px, 46px);
    @include xs {
        font-size: 28px !important;
        line-height: 36px !important;
    }
}

.b-title_norm {
    @include fz(24px, 36px);
    @include xs {
        font-size: 19px !important;
        line-height: 28px !important;
    }
}

.b-title_small {
	@include fz(44px, 53px);
	font-weight: 700;
}

.b-title_center {
    text-align: center;
}

.b-title_right {
    text-align: right;
}

.b-title_h2 {
	@include fz(56px, 67px);
}

.b-title_product {
	@include fz(44px, 53px);
}

.b-title_mar {
	margin-bottom: 0;
}

.b-title_services {
	letter-spacing: 0.7px;
	@include fz(44px, 53px);
}

.b-title_bold {
	font-weight: 700;
}

.b-title_main {
	margin-bottom: 30px;
}

.b-title_vacancies {
	@include xs-mobile {
		@include fz(26px, 34px);
	}
}

.b-title_contacts {
	margin-bottom: 40px;
}

.b-section_title-portfolio {
	margin-bottom: 40px;
}

.b-title_page_top {
	margin-bottom: 0;

	@include mobile480 {
		padding-left: 0;
	}
}