.b-order-fast-cart-trigger{
  display: none;
  position: fixed;
  z-index: 15;
  bottom: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  background-color: $blueColor;

  &:after{
    content: attr(data-fast-order-counter);
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $lightColor;
    color: $blueColor;
    z-index: 2;
    font-size: 14px;
    @include flex-row;
    @include flex-center;
    border: 1px solid $blueColor;
    top:0;
    right: 0;
    transform: translate3d(25%, -25%, 0);
  }

  color: $lightColor;
  border-radius: 50%;
  @include md{
    @include  flex-row;
    @include  flex-center;
  }
  & i{
    display: none;
  }
  &_1{
    & i:nth-child(1){
      display: block !important;
    }
  }
  &_2{
    & i:nth-child(2){
      display: block !important;
    }
  }
}