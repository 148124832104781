.home {

	.b-cover-slider__title {
		font-size: 60px;
		line-height: 1.3;

		@include tb {
			font-size: 46px;
		}

		@include sm {
			font-size: 36px;
			line-height: 1.2;
		}

		@include mobile480 {
			font-size: 20px;
			line-height: 1.2;
		}
	}

	.b-header {
		position: fixed;
		top: 0;
		left: 0;
		background-color: transparent;

		&.active {
			background-color: #fff;
		}
	}

	.section__top-new {
		padding-top: 0;

		.b-first-slider__item {
			margin: 0;
			padding-top: 191px;

			@include sm {
				padding-top: 90px;
			}
		}

		.b-section__inner {
			padding: 0;
		}
	}


	.b-section__content_full {
		//overflow: hidden;
	}


	.b-section_cta,
	.section__contacts {
		overflow: hidden;
	}

	.slider__inner {
		max-width: 100%;
	}

	.b-first-slider__list .slick-dots {
		left: 232px;

		@include sm {
			left: 0;
		}

		@include xs-mobile {
			left: -54px;
		}
	}

}

.b-contacts__contacts_without_form {
	&:before {
		//width: 99.8vw;
	}
}

.post-type-archive-vacancies {



	.b-section_left .b-section__content_full {
		display: flex;
	}

	.b-section_left {
		.b-button_orange {
			padding: 17px 28px 17px 54px;
			position: relative;
			font-size: 17px;
			font-weight: 300;

			&:hover {
				color: #FFF;
				background-color: #FF4600;

				&:after {
					background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTMuNzM5OEwzLjE3NjIxIDcuNTM3NzhINS44NjY2N1YwSDEwLjEzMzNWNy41Mzc3OEgxMi44MjM4TDggMTMuNzM5OFpNNS4zNTcxMiA4LjYwNDQ0TDggMTIuMDAyNEwxMC42NDI4IDguNjA0NDRIOS4wNjY2N1YxLjA2NjY3SDYuOTMzMzNWOC42MDQ0NEg1LjM1NzEyWk0xMi44IDExLjgwNDRIMTZWMTguMjA0NEgwVjExLjgwNDRIMy4yVjE1LjAwNDRIMTIuOFYxMS44MDQ0Wk0xLjA2NjY3IDE3LjEzNzhIMTQuOTMzM1YxMi44NzExSDEzLjg2NjdWMTYuMDcxMUgyLjEzMzMzVjEyLjg3MTFIMS4wNjY2N1YxNy4xMzc4WiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==) center center no-repeat;
				}
			}

			&:after {
				position: absolute;
				content: '';
				display: block;
				left: 24px;
				top: 50%;
				transform: translateY(-50%);
				width: 16px;
				height: 18px;
				z-index: 1;
				background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTMuNzM5OEwzLjE3NjIxIDcuNTM3NzhINS44NjY2N1YwSDEwLjEzMzNWNy41Mzc3OEgxMi44MjM4TDggMTMuNzM5OFpNNS4zNTcxMiA4LjYwNDQ0TDggMTIuMDAyNEwxMC42NDI4IDguNjA0NDRIOS4wNjY2N1YxLjA2NjY3SDYuOTMzMzNWOC42MDQ0NEg1LjM1NzEyWk0xMi44IDExLjgwNDRIMTZWMTguMjA0NEgwVjExLjgwNDRIMy4yVjE1LjAwNDRIMTIuOFYxMS44MDQ0Wk0xLjA2NjY3IDE3LjEzNzhIMTQuOTMzM1YxMi44NzExSDEzLjg2NjdWMTYuMDcxMUgyLjEzMzMzVjEyLjg3MTFIMS4wNjY2N1YxNy4xMzc4WiIgZmlsbD0iI0ZGNDYwMCIvPjwvc3ZnPg==) center center no-repeat;
			}
		}

		.b-button_orange.download-resume-file-btn {
			@include xl {
				margin-top: 0;
			}

			@include xs {
				margin-top: 20px;
			}

		}
	}


}

.single-prices {

	.b-section__row_padding_left {
		.b-button {
			&:first-child {
				min-width: 0;
			}
		}


		@include mobile {
			flex-direction: column;
		}
	}

	.b-section_left {
		.b-button_orange.download-resume-file-btn {
			display: none;
			padding: 17px 28px 17px 54px;
			position: relative;
			font-size: 17px;
			font-weight: 300;

			&:hover {
				color: #FFF;
				background-color: #FF4600;

				&:after {
					background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTMuNzM5OEwzLjE3NjIxIDcuNTM3NzhINS44NjY2N1YwSDEwLjEzMzNWNy41Mzc3OEgxMi44MjM4TDggMTMuNzM5OFpNNS4zNTcxMiA4LjYwNDQ0TDggMTIuMDAyNEwxMC42NDI4IDguNjA0NDRIOS4wNjY2N1YxLjA2NjY3SDYuOTMzMzNWOC42MDQ0NEg1LjM1NzEyWk0xMi44IDExLjgwNDRIMTZWMTguMjA0NEgwVjExLjgwNDRIMy4yVjE1LjAwNDRIMTIuOFYxMS44MDQ0Wk0xLjA2NjY3IDE3LjEzNzhIMTQuOTMzM1YxMi44NzExSDEzLjg2NjdWMTYuMDcxMUgyLjEzMzMzVjEyLjg3MTFIMS4wNjY2N1YxNy4xMzc4WiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==) center center no-repeat;
				}
			}

			&:after {
				position: absolute;
				content: '';
				display: block;
				left: 24px;
				top: 50%;
				transform: translateY(-50%);
				width: 16px;
				height: 18px;
				z-index: 1;
				background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxNiAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTMuNzM5OEwzLjE3NjIxIDcuNTM3NzhINS44NjY2N1YwSDEwLjEzMzNWNy41Mzc3OEgxMi44MjM4TDggMTMuNzM5OFpNNS4zNTcxMiA4LjYwNDQ0TDggMTIuMDAyNEwxMC42NDI4IDguNjA0NDRIOS4wNjY2N1YxLjA2NjY3SDYuOTMzMzNWOC42MDQ0NEg1LjM1NzEyWk0xMi44IDExLjgwNDRIMTZWMTguMjA0NEgwVjExLjgwNDRIMy4yVjE1LjAwNDRIMTIuOFYxMS44MDQ0Wk0xLjA2NjY3IDE3LjEzNzhIMTQuOTMzM1YxMi44NzExSDEzLjg2NjdWMTYuMDcxMUgyLjEzMzMzVjEyLjg3MTFIMS4wNjY2N1YxNy4xMzc4WiIgZmlsbD0iI0ZGNDYwMCIvPjwvc3ZnPg==) center center no-repeat;
			}

			@include xl {
				margin-top: 0;
			}

			@include xs {
				margin-top: 20px;
			}
		}

		.b-section__content {
			display: flex;

			.b-button {
				&:first-child {
					min-width: 0;
				}
			}


			.b-button:not(:last-child) {

				@include ss {
					margin-right: 20px;
				}
			}

			@include mobile {
				flex-direction: column;
			}
		}
	}
}

#video {
	.b-text-block__content {
		width: 100%;
	}
}

.single-offers {

	#advantages {
		margin-top: 70px;
	}

	.b-first-slider__arrow {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNDMxMTIgMC4xNjA4NDRDNS42NjQxNyAtMC4wNjExMTQ4IDYuMDMzNzEgLTAuMDUyMzY0OCA2LjI1NTY3IDAuMTgwOTY5QzYuNDc3OTIgMC40MTQzMDIgNi40Njg1OCAwLjc4MzU1MiA2LjIzNTU0IDEuMDA1OEwxLjc2MDc5IDUuMjQ5ODRIMTMuNDE2N0MxMy43Mzg3IDUuMjQ5ODQgMTQgNS41MTExOCAxNCA1LjgzMzE4QzE0IDYuMTU1MTggMTMuNzM4NyA2LjQxNjUxIDEzLjQxNjcgNi40MTY1MUgxLjc3OTQ2TDYuMjM1NTQgMTAuNjYwOEM2LjQ2ODg3IDEwLjg4MzEgNi40Nzc5MiAxMS4yNTIzIDYuMjU1NjcgMTEuNDg1N0M2LjE0MTA0IDExLjYwNTggNS45ODczMyAxMS42NjY1IDUuODMzMzMgMTEuNjY2NUM1LjY4ODY3IDExLjY2NjUgNS41NDQgMTEuNjEzMSA1LjQzMTEyIDExLjUwNTVMMC4zNDE4MzMgNi42NTgwMUMwLjEyMTMzMyA2LjQzNzggMCA2LjE0NDk3IDAgNS44MzMxOEMwIDUuNTIxMzkgMC4xMjEzMzMgNS4yMjg1NSAwLjM1MjA0MSA0Ljk5ODQzTDUuNDMxMTIgMC4xNjA4NDRaIiBmaWxsPSIjRkY0NjAwIi8+Cjwvc3ZnPgo=);
		background-repeat: no-repeat;
		background-position: center center;
		font-size: 0;

		&.b-first-slider__arrow_next {
			transform: rotate(180deg);
		}
	}

	.b-text-block__buttons-wrapper {
		@include sm {
			display: none;
		}

		&_mob {
			display: none;

			@include sm {
				display: block;
			}
		}
	}
}

.b-card__main {
	.b-card__title h3 {
		@include mobile480 {
			font-size: 20px;
		}
	}
}

.post-type-archive-vacancies {
	.b-first-slider__list .slick-dots {
		@include mobile {
			left: 50%;
			transform: translate(-50%);
		}
	}
}

.single-vacancies {
	.b-main__section {
		overflow: hidden;
	}

	.b-text-block__list-col-icon {
		flex-shrink: 0;

		@include xs-mobile {
			width: 21.5px;
			height: auto;
		}
	}
}

.contacts {
	.b-button[type="submit"] {
		@include mobile480 {
			margin-top: 20px;
		}
	}

	.b-section_padding-norm .b-section__title-wrapper {

		@include ss {
			padding-left: 8px;
			padding-right: 10px;
		}
	}
}

.section__contacts {

	.b-section__inner {
		@include sm {
			padding: 0;
		}
	}

	.b-section-title-and-buttons__buttons {
		@include ss {
			justify-content: flex-start;
			margin-top: 34px;
		}
	}

	.section__form-content {
		@include xs590 {
			padding: 58px 11px 78px 11px;
		}
	}

	.b-first-form__main {
		@include xs590 {
			padding: 0;
		}
	}

	.b-contacts .wpcf7 {
		@include xs590 {
			padding: 0;
		}
	}

	.b-contacts-block__item:not(:last-child) {
		margin-bottom: 42px;
	}
}

.single-products {

	.b-section_padding-bottom-norm.b-section.b-main__section {
		padding-bottom: 0;
	}

	.b-page__main {
		overflow: hidden;
	}

	.section__contacts {
		position: relative;
		background-color: transparent;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 120vw;
			height: 100%;
			background-color: rgba(235, 241, 251, 0.69);
			z-index: -1;
		}

		.b-section__inner {

			.b-contacts.contacts {
				background: none;
			}
		}
	}
}

.page-numbers {
	display: flex;
	justify-content: center;
	margin-top: 10px;


	li {
		&:before {
			display: none;
		}

		padding: 0 !important;

		&:not(:last-child) {
			margin-right: 4px;
		}

		.page-numbers {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			color: #3C70DE;
			background-color: #fff;
			border: 1px solid #3C70DE;
			border-radius: 50%;
			font-style: normal;
			font-weight: bold;
			@include fz(12px, 14px);

			&.current {
				background-color: #3C70DE;
				color: #fff;
			}

			&.prev {
				svg {
					transform: scale(-1, 1);
				}
			}

			&:hover,
			&.current {
				color: #fff;
				background-color: #3C70DE;

				svg path {
					fill: #fff;
				}
			}
		}
	}
}



.nav-links {
	display: flex;
	justify-content: center;
	margin-top: 10px;
	padding-bottom: 20px;

	.page-numbers {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		color: #3C70DE;
		background-color: #fff;
		border: 1px solid #3C70DE;
		border-radius: 50%;
		font-style: normal;
		font-weight: bold;
		@include fz(12px, 14px);

		&:not(:last-child) {
			margin-right: 4px;
		}

		&:hover,
		&.current {
			color: #fff;
			background-color: #3C70DE;

			svg path {
				fill: #fff;
			}
		}

		&.prev {
			svg {
				transform: scale(-1, 1);
			}
		}
	}
}

.js-item-container {
	ul {
		display: flex;
		justify-content: center;
		margin-top: 10px;
	}

	li {
		&:before {
			display: none;
		}
	}

	.paginationjs-page {
		list-style-type: none;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		background-color: #fff;
		padding: 0;
		cursor: pointer;
		border: 1px solid #3C70DE;
		border-radius: 50%;

		&:not(:last-child) {
			margin-right: 4px;
		}

		&.active {
			a {
				color: #fff;
				background-color: #3C70DE;
			}
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			width: 100%;
			height: 100%;
			font-style: normal;
			font-weight: bold;
			@include fz(12px, 14px);

			&:hover {
				color: #fff;
				background-color: #3C70DE;
			}
		}
	}

	.paginationjs-next,
	.paginationjs-prev {
		@extend .paginationjs-page;

		a {
			font-size: 0;
			width: 100%;
			height: 100%;
			background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDYgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjMyOTk3IDEwLjU5NjZDMC41NDIwNzEgMTAuODAyNyAwLjgxNjMzMSAxMC45MDUyIDEuMDkwNTkgMTAuOTA1MkMxLjM3NDY2IDEwLjkwNTIgMS42NTg3NCAxMC43OTUgMS44NzI0OCAxMC41NzUzTDUuMzUyMjUgNi45OTczOUM1Ljc2OTkxIDYuNTg1MTkgNiA2LjAzNTU4IDYgNS40NDk5OEM2IDQuODY0MzkgNS43Njk5MSA0LjMxNDc4IDUuMzY3NTEgMy45MTcyOUwxLjg3MjQ4IDAuMzI5NTYxQzEuNDUxNTQgLTAuMTAxNzMgMC43NjEyNjEgLTAuMTEwOTk5IDAuMzMwNTE1IDAuMzA5Mzg3Qy0wLjEwMTMyMSAwLjcyOTc3MyAtMC4xMTAwNDUgMS40MjAwNiAwLjMxMDM0MSAxLjg1MTM1TDMuODA0ODMgNS40NjA4OUwwLjMwOTc5NiA5LjA1NDA3Qy0wLjExMTEzNSA5LjQ4NTkgLTAuMTAxODY2IDEwLjE3NjIgMC4zMjk5NyAxMC41OTY2WiIgZmlsbD0iIzNDNzBERSI+PC9wYXRoPgo8L3N2Zz4=) center center no-repeat;

			&:hover {
				background: #3C70DE url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDYgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjMyOTk3IDEwLjU5NjZDMC41NDIwNzEgMTAuODAyNyAwLjgxNjMzMSAxMC45MDUyIDEuMDkwNTkgMTAuOTA1MkMxLjM3NDY2IDEwLjkwNTIgMS42NTg3NCAxMC43OTUgMS44NzI0OCAxMC41NzUzTDUuMzUyMjUgNi45OTczOUM1Ljc2OTkxIDYuNTg1MTkgNiA2LjAzNTU4IDYgNS40NDk5OEM2IDQuODY0MzkgNS43Njk5MSA0LjMxNDc4IDUuMzY3NTEgMy45MTcyOUwxLjg3MjQ4IDAuMzI5NTYxQzEuNDUxNTQgLTAuMTAxNzMgMC43NjEyNjEgLTAuMTEwOTk5IDAuMzMwNTE1IDAuMzA5Mzg3Qy0wLjEwMTMyMSAwLjcyOTc3MyAtMC4xMTAwNDUgMS40MjAwNiAwLjMxMDM0MSAxLjg1MTM1TDMuODA0ODMgNS40NjA4OUwwLjMwOTc5NiA5LjA1NDA3Qy0wLjExMTEzNSA5LjQ4NTkgLTAuMTAxODY2IDEwLjE3NjIgMC4zMjk5NyAxMC41OTY2WiIgZmlsbD0iI2ZmZmZmZiI+PC9wYXRoPgo8L3N2Zz4=) center center no-repeat;
			}
		}

		&.disabled {
			display: none;
		}
	}

	.paginationjs-prev {
		transform: rotate(180deg);
	}
}


.about-us {

	.b-section_padding-norm.b-section.b-main__section {
		padding-top: 0;
	}

	.top-block {
		padding: 0 15px;


		.b-section__inner-small {
			&>.b-text-block__content {
				padding-left: 0;
			}
		}
	}

	.b-text-block__content.b-text-block__content_p.b-section_notice {
		//padding: 50px 80px 50px 55px;
		padding-left: 55px;

		@include ss {
			padding-left: 15px !important;
		}


	}

	.just_wrapper {
		max-width: 60%;
		margin-bottom: 20px;

		@include ss {
			max-width: 100%;
		}
	}

	.b-section_padding-norm.b-section.b-main__section {
		//padding-bottom: 0;
	}
}

.b-section_padding-norm.b-main__section.section__contacts .b-section__inner {
	@include sm {
		padding: 0;
	}
}

.b-contacts {
	@include sm {
		padding: 0 15px;
	}
}

.single-services {
	.b-product__space {
		display: none;
	}

	.b-main__section {
		overflow: hidden;
	}

	.b-main__section.support-request-btn {
		overflow: visible;

		.b-button_orange_product {
			font-size: 16px;
			line-height: 20;
		}
	}

	.b-section__content_full {
		@include mobile {
			align-items: flex-start;
		}
	}

	.b-2-col-with-image-block__content {
		min-height: 300px;

		@include laptop1350 {
			min-height: 0;
		}
	}

	&.consultation-line {
		.b-2-col-with-image-block__content {
			min-height: 410px;

			@include laptop1350 {
				min-height: 0;
			}
		}

		.b-product__content-list {
			margin-bottom: 0;
		}

		.b-section_padding-norm {
			padding-top: 10px;
		}

	}

	.b-section_buttons {
		padding-top: 25px;
		padding-bottom: 10px;
	}

	.b-section_padding-bottom-norm.b-section.b-main__section.content-anchor-block {
		padding-bottom: 20px;
	}
}

.b-phone-data__inner {
	position: relative;
}


.phone {
	display: none;
	position: fixed;
	bottom: 28px;
	right: 10px;
	z-index: 4;

	@include sm {
		display: block;
	}

	&__btn {
		position: relative;
		width: 44px;
		height: 44px;
		background: #FF4600;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 0 20px rgba(255, 70, 0, 0.360989);
		border-radius: 50%;
		border: none;
		appearance: none;
		z-index: 3;
	}

	&__inner {
		overflow: hidden;
		height: 0;
		width: 0;

		a {
			width: 00%;
			height: 100%;
			background-color: #fff;
			font-family: 'SF Pro Display', sans-serif;
			font-size: 16px;
			line-height: 19px;
			color: #3C70DE;
		}
	}

	&.active {
		.phone__inner {
			height: 44px;
			width: auto;
			background-color: #fff;
			border-radius: 22px;
			animation: width ease-in-out .3s forwards;
			text-align: center;
			padding: 12px;
			box-shadow: 0px 0px 20px rgba(35, 62, 119, 0.583642);
		}

		.phone__btn {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}


@keyframes width {
	from {
		width: 0;
		left: 0;
	}

	to {
		width: 300px;
		left: -266px;
	}
}

table {
	@include sm {
		width: 1024px;
		overflow-y: auto;
	}

	thead {
		background-color: rgba(60, 112, 222, 0.7);
		border-radius: 0 100px 0 0;
		color: #fff;
		font-weight: 500;
		@include fz(16px, 22px);

		tr {
			th {
				padding: 19px 30px;
				border: 1px solid #fff;

				&:first-child {
					text-align: left;
				}

				&:last-child {
					background-color: rgba(60, 112, 222, 0.7);
				}
			}
		}
	}

	tbody {
		font-weight: 300;
		color: #1F1D25;
		@include fz(18px, 22px);

		tr {
			&:nth-child(odd) {
				background-color: #F2F5FD;

				td:last-child {
					background-color: rgba(60, 112, 222, 0.09);
					text-align: center;
					padding: 18px 0;
					font-weight: bold;
					@include fz(18px, 22px);
					color: #1F1D25;
				}
			}

			&:nth-child(even) {
				background-color: #F7F9FE;

				td:last-child {
					background-color: #F6F9FE;
					text-align: center;
					padding: 18px 0;
					font-weight: bold;
					@include fz(18px, 22px);
					color: #1F1D25;
				}
			}

			td {

				border: 1px solid #fff;
				padding: 18px 36px;
			}
		}
	}
}

.b-sidebar {
	[type="submit"] {
		margin-top: 0;
		margin-bottom: 15px;
		box-shadow: none;
	}

	.b-sidebar__footer-row {
		flex-direction: column;
		align-items: flex-start;
	}
}

.term-1c-predprijatie,
.term-produkty-conto {
	.b-section__inner_with-sidebar {
		@include to(1760px) {
			padding: 0 15px !important;
		}

		@include lg {
			padding: 0 15px !important;
		}
	}
}

.single-news {
	.b-section_slider {
		.b-section__content.b-section__content_full {
			overflow: hidden;
		}
	}
}



.single-products {

	.b-2-col-with-image-block__image.b-2-col-with-image-block__image_bg {
		height: 500px;
		max-width: 800px;
		margin-bottom: auto;
		margin-left: auto;

		@include laptop1470 {
			max-width: 700px;
		}

		@include laptop1350 {
			max-width: 700px;
			height: 200px;
		}

		@include laptop1199 {
			max-width: 450px;
			height: 300px;
		}

		@include md {
			margin-left: auto;
			max-width: 350px;
		}

		@include sm850 {
			display: none;
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}

		&:after {
			@include laptop1350 {
				top: -320px;
			}

			@include md {
				left: -362px;
			}
		}

		.b-section_discount {
			top: 30px;
			right: auto;
			left: 0;

		}

	}

	.b-section__inner-small {
		padding: 0;
	}

	.b-text-block__content.b-text-block__content_p {}

	.b-description_text-wrapper {
		min-height: 390px;

		@include laptop1199 {
			min-height: 100px;
		}

		@include sm850 {
			min-height: 100px;
		}
	}

	.b-2-col-with-image-block__content {
		@include sm850 {
			max-width: 100%;
		}
	}

	.b-2-col-with-image-block__title {
		@include sm850 {
			margin-bottom: 20px;
		}
	}

	.b-section_with-bg {
		@include sm850 {
			padding-bottom: 10px;
		}
	}

	.b-text-block__content {
		@include mobile-320 {
			margin-bottom: 20px;
		}
	}

	//.b-contacts__contacts_pos {
	//	margin-bottom: -80px;
	//
	//	@include ss {
	//		margin-bottom: -120px;
	//	}
	//
	//	&:before {
	//		@include xs {
	//			background-size: cover;
	//		}
	//	}
	//
	//	.b-contacts-block__footer {
	//		@include xs {
	//			margin-bottom: 20px;
	//		}
	//	}
	//
	//	.b-contacts-block__item {
	//		@include ss {
	//			width: 100%;
	//		}
	//	}
	//}
}


.b-section__content {
	.b-section__text-content {
		p {
			margin-bottom: 20px;
		}

		ul {
			margin-bottom: 30px;
		}
	}
}


.post-type-archive-portfolio {
	.b-section__content_with-sidebar {
		@include md {
			max-width: 100%;
		}
	}
}


.post-type-archive-support ,
.post-type-archive-services {

	.b-cards-list__row {
		@include sm {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.b-cards-list__item {
		padding: 0;

		@include sm {
			width: 46%;
		}

		@include ss {
			width: 100%;
		}
	}

	.b-horizontal-card__inner {
		height: 100%;
	}

	.b-horizontal-card__content {
		display: flex;
		flex-direction: column;

		@include sm {
			width: 100%;
		}
	}

	.b-horizontal-card__footer {
		margin-top: auto;
	}
}

.b-cards-list__item {
	order: var(--order);
}


.b-section__content.b-section__content_full.d-flex {
	display: flex;

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;

		.b-button {
			margin-right: 20px;
			margin-bottom: 20px;

			&:last-child {
				margin-right: 0;
				margin-bottom: 0;
			}
		}
	}
}

.home .b-first-slider__list {
	max-width: 100vw;
	overflow: hidden;
}

.post-type-archive-news {
	.b-contacts__contacts_without_form {
		overflow: hidden;
	}
}

.b-button_dots {
	border-radius: 30px;

	.pe-7s-more._grad-text {
		margin-right: 0;
		position: relative;

		&:before {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
		}
	}
}

.b-card_news {
	.b-button {
		@include fz(16px, 19px);
	}
}

.b-modal-box[data-modal-window="fast-order-popup"] {
	.b-modal {
		overflow-y: unset;
	}
}

.post-type-archive-offers {
	.b-card__header {
		display: none;
	}
}

.soprovozhdenie-its-services {
	.b-link-icon {
		&:before {
			display: none;
		}
	}

	.content-anchor-block {
		padding-bottom: 0;
		position: relative;

		@include laptop1199 {
			top: 0;
		}
	}

	.soprovozhdenie-its__main-content-block {
		padding-bottom: 100px;

		@include laptop1199 {
			padding-bottom: 30px;
		}
	}

	.b-product__space {
		display: none;
	}

	.b-product__content-list {
		margin-bottom: 0;
	}
}

.page {
	.b-main {
		overflow-x: hidden;
	}


	.just_wrapper {
		max-width: 50%;

		@include ss {
			max-width: 100%;
		}
	}

	.b-text-block__content {}
}

.prices-main {
	&__category-items {
		display: flex;
		//justify-content: flex-start;
		//flex-wrap: wrap;
		flex-direction: column;
	}

	&__product-item {
		width: 100%;
		order: var(--order);
	}
}



.support-page {
	padding: 60px 15px 0 15px;
}


.page-template-page-template_supports {

	.b-horizontal-card__image {
		display: flex;
		justify-content: center;
		align-items: center;

		@include ss {
			padding-bottom: 0;
		}

		svg {
			max-width: 60%;
			max-height: 60%;

			ellipse,
			path {
				fill: grey !important;
			}
		}
	}
}


.space-block {
	width: 100%;
	padding-top: 200px;

	@include laptop {
		padding-top: 100px;
	}

	@include lg {
		padding-top: 0;
	}
}

.b-product_text-block {
	@include ss {
		//margin-top: -50px;
		//margin-bottom: -50px;
	}

	.b-section__inner-small {
		@include ss {
			padding: 0;
		}
	}
}

.b-section_padding-norm {
	@include ss {
		padding-top: 26px;
		padding-bottom: 26px;
	}
}

.text-video {
	&.b-section_padding-norm {
		@include sm {
			padding-top: 26px;
			padding-bottom: 26px;
		}
	}
}

.video-modal {
	&.b-section_padding-norm {
		@include sm {
			padding-top: 26px;
			padding-bottom: 26px;
		}
	}
}

.b-2-col-with-image-block__image {
	img {
		max-height:100%;
	}
}

.bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;

	img {
		width: 100%;
	}
}

.b-content-table {
	table {
		tbody {
			tr {
				td:nth-child(1n) {
					padding: 18px 10px;
				}
			}

		}

	}
}

.wpcf7mailsent  form {
	.wpcf7-response-output {
		text-align: center;
		font-size: 22px;
	}
}