.b-product {
	position: relative;
	width: 100%;
}

.b-product__inner {}

.b-product__content {}

.b-product__title {
	margin-bottom: 30px;
	@include xs {
		margin-bottom: 18px;
	}
	@include h_inh;
}

.b-product__image {
	width: 100%;
	position: relative;
	padding-bottom: 49%;
	margin-bottom: 30px;
	@include xs {
		margin-bottom: 18px;
	}
}

.b-product__image-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.b-product__text {
	margin-bottom: 40px;
	@include xs {
		margin-bottom: 20px;
	}
	& strong {
		font-weight: 700;
	}
	& p {
		margin-bottom: 30px;
		@include xs {
			margin-bottom: 20px;
		}
	}
}

.b-product__row {
	@include flex-row;
}

.b-product__space {
	height: 40px;
	width: 100%;
}

.b-product__row_for-cols {
	@include row;
}

.b-product__button {
	margin: 15px;
}

.b-product__content-list {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: 60px;
	margin-top: -120px;

	@include laptop {
		margin-top: 0;
	}

	@include sm {
		margin-bottom: 30px;
	}

	@include xs {
		margin-bottom: 20px;
	}
}

.b-product__content-list-title {
	margin-bottom: 20px;
	@include fz(18px, 24px);
	font-weight: 600;
	@include xs {
		margin-bottom: 15px;
	}
	@include h_inh;
}

.b-product__content-list-item {
	font-family: 'SF Pro Display', sans-serif;
	font-style: normal;
	font-weight: normal;
	@include fz(12px, 14px);
	text-transform: uppercase;
	color: #3C70DE;

	//text-decoration: underline;
	//text-decoration-color: $orangeColor;
	//color: $darkColor;
	//cursor: pointer;
	//margin-top: 5px;
	//margin-bottom: 5px;
	//& a {
	//    @include inh;
	//}
	//@include hover {
	//    color: $not-grad;
	//}

	&:not(:last-child) {
		margin-right: 10px;
	}

	margin-bottom: 20px;
}

.b-product_text-block {
	margin-bottom: 40px;
	//padding-left: 15px;
	//padding-right: 15px;

	@include xs {
		margin-bottom: 20px;
	}
}


@media screen and (max-width: 1600px) {
	.single-services .b-section_big-padding {
		padding-bottom: 40px;
		overflow: hidden;
	}
}


.single-services {
	.b-product__content-list {
		padding-left: 40px;

		@include laptop {
			padding-left: 0;
		}

		@include sm {
			padding: 0 !important;
		}

		@include xs-mobile {
			justify-content: center;
		}
	}

	.b-section_big-padding {
		padding-top: 0;

		@include xl {
			padding-bottom: 40px;
		}

		@include sm {
			padding-bottom: 20px;
		}
	}

	.b-section__inner_pt {
		padding-top: 80px;
		//padding-bottom: 336px;

		@include md990 {
			padding-bottom: 0;
		}

		@include sm {
			padding-top: 0;
		}
	}

	.b-section__content_full {

		@include mobile {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	.b-sale-block__button {
		padding: 17px 26px 17px;
		border-radius: 30px;
		max-height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: max-content;

		@include mobile {
			margin: 0 auto;
		}
	}

	.b-2-col-with-image-block__image {
		img{
			max-width: 100%;
		}

		width: 56%;
		@include tb {
			display: none;
		}

		//img {
		//	width: 840px;
		//	top: 0;
		//	right: 0;
		//
		//	@include xl1750 {
		//		width: 784px;
		//	}
		//
		//	@include md990 {
		//		position: absolute;
		//		top: -50px;
		//		right: 0;
		//	}
		//
		//	@include md {
		//		right: calc(50% - 258px);
		//	}
		//
		//	@include sm {
		//		width: 550px;
		//		right: 0;
		//	}
		//}
	}



	.b-2-col-with-image-block__image_bg {
		top: -80px;
		z-index: 1;



		//@include md990 {
		//	height: 330px;
		//	top: -30px;
		//}
		//
		//@include ss {
		//	height: 320px;
		//}
		//
		//@include mobile480 {
		//	height: 260px;
		//}
		//
		//@include xs-mobile {
		//	height: 164px;
		//}
		//
		//@include mobile {
		//	top: 0;
		//}
	}

	.b-2-col-with-image-block__row {

		.b-button {
			max-height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 15px;

			@include laptop1350 {
				margin-bottom: 30px;
			}

			@include laptop1470 {
				margin-right: 15px;
			}

			@include xs-mobile {
				justify-content: center;

			}

			@include mobile480 {
				margin-right: 0;
				margin-bottom: 30px;
			}
		}
	}

	.b-section_padding-top-norm {
		//.b-section__inner {
		//	@include xl {
		//		padding: 81px 0 80px;
		//	}
		//
		//	@include laptop1470 {
		//		padding: 81px 15px 80px;
		//	}
		//
		//	@include laptop1350 {
		//		padding: 81px 15px 20px;
		//	}
		//
		//	@include sm {
		//		padding: 20px 15px 10px;
		//	}
		//}
	}

	.b-icon-card__title {
		font-size: 26px;
		line-height: 1.38;


		@include ss {
			font-size: 20px;
		}
	}


	#install-programm {
		margin-bottom: 90px;

		@include sm {
			margin-bottom: 40px;
		}
	}
}

