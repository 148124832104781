.b-review {}

.b-review__inner {}

.b-review__row {
    @include flex-row;
    align-items: flex-start;
    justify-content: center;
}

.b-review__image-wrap {
    @include col(4);
    @include col-xs(12);
}

.b-review__image {
    padding-bottom: 71.35%;
    overflow: hidden;
    position: relative;
    @include xs {
        padding-bottom: 40%;
    }
}

.b-review__image-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.b-review__content {
    @include col(8);
    @include col-xs(12);
    margin-left: -15px;
    padding: 26px 40px;
    background-color: #fafafa;
    position: relative;
    @include xs {
        padding: 14px 28px;
        margin-left: 15px;
        margin-top: -15px;
    }
}

.b-review__header {
    margin-bottom: 15px;
}

.b-review__title {
    @include fz(18px, 27px);
    font-weight: 500;
    color: $darkColor;
    @include h_inh;
}

.b-review__main {}

.b-review__text {
    @include fz(14px, 21px);
    font-weight: 500;
    color: $darkColor;
}

.reviews__bottom-content-button {
	padding: 17px 49px 17px;
	margin-left: 3px;
	max-height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: max-content;

	@include mobile {
		padding: 17px 30px 17px;
	}
}

.reviews .b-first-slider__item {
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
}