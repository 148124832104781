.b-sign-up-for-newsletter {}

.b-sign-up-for-newsletter__inner {}

.b-sign-up-for-newsletter__row {
    @include flex-row;
    @include row;
    align-items: center;
}

.b-sign-up-for-newsletter__title {
    @include col(6);
    @include col-sm(12);
    @include col-xs(12);
}

.b-sign-up-for-newsletter__content {
    @include col(6);
    @include col-sm(12);
    @include col-xs(12);
    @include flex-row;
    justify-content: flex-end;
    align-items: center;
    @include sm {
        justify-content: center;
    }
}

.b-sign-up-for-newsletter__button {
    margin-right: 20px;
    @include sm {
        margin-right: auto;
    }
}

.b-sign-up-for-newsletter__soc-link {
    &:not(:last-child) {
        margin-right: 15px;
    }
}