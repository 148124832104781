.b-description {
    font-weight: 500;
    & strong {
        font-weight: 700;
    }
}

.b-description_light {
    color: $lightColor;
}

.b-description_dark {
    color: $darkColor;
}

.b-description_big {
    @include fz(36px, 53px);
}

.b-description_norm {
    @include fz(18px, 32px);
}

.b-description_small {}

.b-description_center {
    text-align: center;
}

.b-description_right {
    text-align: right;
}

.b-description_padding_left {
	padding-left: 67px;

	@include sm {
		padding-left: 0;
	}
}

.b-description_services {
	padding-left: 31px;
}