.b-2-col-small-block {}

.b-2-col-small-block__inner {}

.b-2-col-small-block__row {
    @include flex-row;
    @include flex-center;
}

.b-2-col-small-block__image {
    @include col(6);
    @include col-sm(8);
    @include sm {
        margin-left: auto;
        margin-right: auto;
    }
    @include col-xs(10);
    @include col-mobile(12);
}

.b-2-col-small-block__image-inner {
    width: 100%;
    padding-bottom: 66%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.b-2-col-small-block__content {
    @include col(6);
    @include col-sm(12)
}

.b-2-col-small-block__title {
    margin-bottom: 15px;
    @include fz(24px, 36px);
    font-weight: 600;
    @include h_inh;
}

.b-2-col-small-block__text {
    margin-bottom: 20px;
    & ul,
    & p {
        @include fz(16px, 24px);
        & a {
            color: $darkColor;
            text-decoration: underline;
            text-decoration-color: $orangeColor;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.b-2-col-small-block__button {}