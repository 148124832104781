.b-image-card {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    overflow: hidden;
    display: block;
    @include hover {
        & .b-image-card__image {
            transform: scale(1.05);
        }
    }
}

.b-image-card__inner {
    width: 100%;
    padding-bottom: 71%;
    position: relative;
}

.b-image-card__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: $transition;
}