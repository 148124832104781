.b-vacancies-block {}

.b-vacancies-block__inner {}

.b-vacancies-block__row {
    @include flex-row;
    @include row;
}

.b-vacancies-block__manager {
    @include col(2);
    @include col-lg(3);
    @include col-md(4);
    @include col-sm(6);
    @include col-xs(8);
    @include md {
        margin-left: auto;
        margin-right: auto;
    }
    @include sm {
        margin-left: auto;
        margin-right: auto;
    }
    @include xs {
        margin-left: auto;
        margin-right: auto;
    }
}

.b-vacancies-block__content {
    @include col(10);
    @include col-lg(9);
    @include col-md(12);
    @include col-sm(12);
    @include col-xs(12);
}