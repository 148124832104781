.b-horizontal-card {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.14);

    @include hover {
        & .b-horizontal-card__image-inner {
            transform: scale(1.025);
        }
    }
}

.b-horizontal-card__inner {
    @include flex-row;

	@include sm {
		flex-direction: column;
	}
}

.b-horizontal-card__image {
    display: block;
    width: 35%;
    position: relative;
    overflow: hidden;

	@include sm {
		width: 100%;
		height: 200px;
	}

	@include ss {
		width: 100%;
		height: 300px;
	}

    @include xs {
        width: 100%;
        padding-bottom: 42%;
    }

	@include mobile {
		height: 200px;
	}
}

.b-horizontal-card__image-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: $transition;
}

.b-horizontal-card__content {
    padding: 31px 28px;
    width: 65%;
    @include xs {
        width: 100%;
    }
}

.b-horizontal-card__header {
    margin-bottom: 26px;
}

.b-horizontal-card__title {
    display: block;
    @include fz(18px, 24px);
    color: $darkColor;
    font-weight: 600;
    @include h_inh;
}

.b-horizontal-card__main {
    margin-bottom: 18px;
}

.b-horizontal-card__desc {}

.b-horizontal-card__footer {}

.b-horizontal-card__button {
    justify-content: start;
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width: 240px;
}