.b-submenu {
    position: fixed;
    z-index: 5;
    width: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    mix-blend-mode: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    transform: translate3d(0, -200%, 0);
    &.active {
        transform: translate3d(0, 0, 0);
    }
    transition: $transition;
}

.b-submenu__inner {
    @include wrapper;
}

.b-submenu__content {
    padding-top: 15px;
    padding-bottom: 15px;
}

.b-submenu__header {
    position: relative;
    padding-top: 10px;
    padding-bottom: 15px;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        width: 100%;
        height: 1px;
        // background: $grad;
        background-color: $orangeColor;
    }
    @include flex-row;
    @include flex-center;
}

.b-submenu__title {
    @include fz(24px, 36px);
    font-weight: 500;
    // color: #000;
    color: $blueColor;
    width: calc(100% - 25px - 10px);
    margin-right: 10px;
}

.b-submenu__close {
    cursor: pointer;
    width: 25px;
    height: 25px;
    @include flex-row;
    align-items: center;
    justify-content: flex-end;
    // color: #000;
    color: $orangeColor;
    @include fz(24px, 24px)
}

.b-submenu__main {
    padding-top: 10px;
    padding-bottom: 10px;
}

.b-submenu__main ul {
    @include flex-row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
}

.b-submenu__main ul li {

    width: calc((100% / 12) * 4 - 20px);
    margin: 0;
    @include md {
        width: calc((100% / 12) * 6 - 20px);
    }

	&:before,
	&:after{
		display: none;
	}

    &.active {
        a:after {
            opacity: 1;
        }
    }
}

.b-submenu__main ul li a {
    display: inline-block;
    text-transform: uppercase;
    color: #13111C;
    @include fz(13px, 16px);
    font-weight: 400;
    position: relative;
	padding: 10px 25px;
	border-radius: 16.5px;

	&:before {
		content: '';
		position: absolute;
		width: 1px;
		height: 10px;
		background: #FF4600;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);

	}
    //&:after {
    //    content: '';
    //    position: absolute;
    //    width: 100%;
    //    transition: $transition;
    //    top: 100%;
    //    left: 0;
    //    height: 1px;
    //    // background: $grad;
    //    background: $orangeColor;
    //    opacity: 0;
    //}
    @include hover {
	    background: #3A6DD8;
	    color: #ffffff;

        &:before {
	        background: #ffffff;
        }
    }
}