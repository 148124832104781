.b-sale-block {}

.b-sale-block__inner {
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
}

.b-sale-block__content {}

.b-sale-block__title {
    margin-bottom: 20px;
}

.b-sale-block__text {
    margin-bottom: 20px;
    & ul {
        & li {
            text-transform: uppercase;
        }
    }
}

.b-sale-block__button {
    min-width: 230px;
}