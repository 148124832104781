.b-sidebar-trigger {
    display: none;
    border-radius: 4px;
    background: $grad;
    color: $lightColor;
    font-size: 14px;
    line-height: 14px;
    padding: 8px 26px;
    cursor: pointer;
    & i {
        margin-right: 10px;
    }
    @include sm {
        display: inline-flex;
        text-align: center;
        align-items: center;
        justify-content: center;
		max-height: 40px;
		max-width: 118px;
		width: 100%;
		border-radius: 25px;
		padding: 14px;
    }


	@include xs-mobile {
		margin-bottom: 70px;
	}
}

.b-sidebar-filter-icon {
	margin-right: 10px;
}