.b-text-and-image {}

.b-text-and-image__inner {}

.b-text-and-image__row {
    @include flex-row;
    @include row;
}

.b-text-and-image__content {
    @include col(6);
    @include col-md(12);
    @include col-sm(12);
    @include col-xs(12);
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
    & p {
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }
}

.b-text-and-image__other {
    @include col(6);
    @include col-md(12);
    @include col-sm(12);
    @include col-xs(12);
}