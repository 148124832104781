.b-contacts {
	padding: 0 15px;
	width: 100%;

	@include sm {
		padding: 0;
	}
}

.b-contacts__inner {}


.b-contacts__contetn {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.b-contacts__title {
    margin-bottom: 15px;
}

.b-contacts__description {
    margin-bottom: 0;
	font-size: 20px;
	line-height: 1.4;
	color: #1F1D25;
	font-weight: 300;

	p {
		font-size: 20px;
		line-height: 1.4;
		color: #1F1D25;
		font-weight: 300;

		@include sm {
			font-size: 16px;
		}
	}
}

.b-contacts__row {
    @include flex-row;
    @include row;
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;



	@include sm850 {
		//flex-wrap: wrap;
		flex-direction: column;
	}
}

.b-contacts__form {
    //@include col(6.5);
    @include col-sm(12);
	padding: 0 30px;
    @include sm {
        order: 2;
    }
    z-index: 2;
}

.b-contacts__contacts {
    @include col(5.5);
    @include col-sm(12);
    @include sm {
        order: 1;
    }
	width: 100%;
	max-width: 462px;
	margin: 0;
    z-index: 1;

	@include laptop {
		margin-right: -15px;
	}

	@include sm850 {
		margin-left: -15px;
		max-width: 100vw;
		width: 100vw;
	}

	@include sm {
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		width: 100%;
	}

	@include mobile {
		width: calc(100% + 30px);
	}
}

/*Contact form 7*/
.b-contacts
{
    .wpcf7 {
        @extend .b-contacts__form;
	    width: 100%;
	    border: none;
	    box-shadow: none;

	    @include sm {
		    padding: 0 15px;
	    }

    }
}