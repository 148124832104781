.b-calculator {
    position: relative;
}

.b-calculator__inner {}

.b-calculator__filters {
    @include flex-row;
    @include flex-center;
    margin-bottom: 40px;
    @include xs {
        margin-bottom: 25px;
    }
}

.b-calculator__filter {
    cursor: pointer;
    padding: 8px 14px;
    color: $darkColor;
    @include fz(12px, 18px);
    background-color: #fafafa;
    transition: $transition;
    &.active {
        color: $lightColor;
        background-color: transparent;
        &:after {
            transition: $transition;
            opacity: 1;
        }
    }
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $grad;
        opacity: 0;
        z-index: 1;
    }
    & span {
        z-index: 2;
        position: relative;
    }
    @include sm {
        width: 33%;
        text-align: center;
    }
    @include xs {
        // width: 50%;
        width: 100%;
        text-align: center;
    }
}

.b-calculator__row {
    @include flex-row;
    @include row;
}

.b-calculator__output {
    @include col(9);
    @include col-md(12);
    @include col-sm(12);
    @include col-xs(12);
}

.b-calculator__price {
    @include col(3);
    @include col-md(12);
    @include col-sm(12);
    @include col-xs(12);
}

.b-calculator__price-inner {
    position: sticky;
    top: 0;
}

.b-calculator__price-title {
    @include fz(18px, 36px);
    // font-weight: 800;
    font-weight: 600;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 20px;
    padding-top: 10px;
    margin-bottom: 15px;
    @include h_inh;
}

.b-calculator__price-row {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    @include flex-row;
    & table {
        width: 100%;
        border-collapse: collapse;
        & tr {
            @include xl {
                @include flex-row;
                @include flex-center;
                &:not(:last-child) {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $blueColor;
                }
            }
            & td {
                padding-top: 10px;
                padding-bottom: 10px;
                @include fz(14px, 18px);
                & span {
                    // @include grad($grad, $not-grad);
                    color: $orangeColor;
                    cursor: pointer;
                    @include fz(24px, 18px);
                    padding: 5px 3px 2px 3px;
                }
                &:not(:first-child) {
                    text-align: right;
                }
                @include xl {
                    width: 50%;
                    &:nth-child(1) {
                        order: 1;
                        text-align: left !important;
                    }
                    &:nth-child(2) {
                        order: 3;
                        text-align: left !important;
                    }
                    &:nth-child(3) {
                        order: 4;
                        text-align: right !important;
                    }
                    &:nth-child(4) {
                        order: 2;
                        text-align: right !important;
                    }
                }
            }
        }
    }
}

.b-calculator__price-row_table-td-2-3-no-wrap {
    table tr td {
        &:nth-child(2), &:nth-child(3) {
            white-space: nowrap;
        }
    }
    table tr td:not(:last-child) {
        padding-right: 5px;
    }
}

.b-calculator__price-row table tr {
    vertical-align: top;
}

.b-calculator__price-sum {
    @include fz(16px, 21px);
    // font-weight: 900;
    font-weight: 600;
    text-align: right;
    width: 100%;
    padding: 0 13px;
}

.b-calculator__table {
    margin-bottom: 25px;
    & td {
        &:nth-child(2) {
            width: 120px;
        }
        &:nth-child(3) {
            width: 180px;
        }
    }
}