c.b-table {
    width: 100%;
}

.b-table__inner {
    width: 100%;
}

.b-table__inner_last-right {
    & table {
        & tr {
            & td {
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

.b-table__inner_last-p-0 {
    & table {
        & tr {
            & td {
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.b-table__inner_2nd-text-fz-14-fw-big {
    & table {
        & tr {
            & td {
                &:nth-child(2) {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
}

.b-table__inner_2nd-text-right {
    & table {
        & tr {
            & td {
                &:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }
}

.b-table__inner_mw-1054 {
    overflow: auto;
    & table {

        @include laptop1199 {
			min-width: 1054px;
        }

		@include md {
            min-width: 0;
        }
    }
}

.b-table__inner_mw-500 {
    overflow: auto;
    & table {
        min-width: 500px;
        @include md {
            min-width: 0;
        }
    }
}

.b-table_md-full-w {
    @include md {
        & table {
            & tr {
                & td {
                    width: 100% !important;
                }
            }
        }
    }
}

.b-table__inner_table-border-top {
    & table {
        border-top: 1px solid rgba($not-grad, 0.6);
    }
}

.b-table__inner table {
    position: relative;
    width: 100%;
    @include fz(14px, 21px);
    color: $darkColor;
    border-collapse: collapse;
}

.b-table__inner table tr {
    text-align: left;
    @include md {
        @include flex-row;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #dedede;
        &>td {
            &:first-child {
                width: 100%;
                @include fz(16px, 16px);
                font-weight: 600;
                color: $blueColor;
            }
            &:not(:first-child) {
                width: 50%;
            }
            text-align: left !important;
            border: none;
            & .b-input-range {
                width: 100%;
                & .b-input-range__output {
                    flex-grow: 1;
                }
            }
            & .b-button {
                width: 100% !important;
                min-width: 0 !important;
            }
        }
    }
    @include xs {
        &>td {
            & .b-button {
                border-radius: 4px;
                padding: 8px 26px;
                font-size: 14px;
                line-height: 14px;
                width: auto !important;
                position: relative;
                left: 100%;
                transform: translate3d(-100%, 0, 0);
            }
        }
    }
}

.b-table__inner table th {
    text-align: left;
    text-transform: uppercase;
    // font-weight: 900;
    font-weight: 600;
    padding-left: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
    border-bottom: 1px solid $not-grad;

    @include ss {
        display: none;
    }
}

.b-table__inner table td {
    text-align: left;
    padding-left: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
    border-bottom: 1px solid rgba($not-grad, 0.6);
    &:last-child {
        padding-right: 0;
    }
}

.b-table__title {
    margin-bottom: 30px;
    @include fz(24px, 36px);
    color: $darkColor;
    font-weight: 600;
    @include xs {
        margin-bottom: 20px;
    }
    @include h_inh;
}

.b-table__title_center {
    text-align: center;
}

.b-table__title_upper {
    text-transform: uppercase;
}