.b-form {
  @include flex-colm;
}
.b-form_padding {
  padding: 20px 30px;
}
.b-form_shadow {
  box-shadow: 0 0 30px 0 rgba(#000, 0.05);
}
.b-form_radius {
  border-radius: $radius;
}
.b-form__row {
  @include flex-row;
}
.b-form__row_with-cols {
  @include row;
}
.b-form__col {
  @include col(6);
}
.b-form__button {
  margin-top: 15px;
}
