.b-separator {
  max-width: 120px;
  width: 100%;
  height: 22px;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 100%;
    background-image: url(../../assets/img/separator.png);
    animation: separator 6s linear infinite;
  }
}
.b-separator_center {
  margin-left: auto;
  margin-right: auto;
}
.b-separator_right {
  margin-left: auto;
}
@keyframes separator {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
