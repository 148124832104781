.b-gallery-images {
    position: relative;
    @include flex-colm;
    width: 100%;
}

.b-gallery-images__arrows {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 50px;
    @include flex-row;
    @include flex-center;
    border: 1px solid $not-grad;
    border-radius: 5px;
    position: relative;
    &:after {
        content: '';
        width: 1px;
        height: 60%;
        background-color: $not-grad;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
    @include xs {
        width: 88px;
        height: 44px;
        order: 2;
        margin-bottom: 0;
        margin-top: 20px;
    }
}

.b-gallery-images__arrow {
    @include flex-row;
    @include flex-center;
    z-index: 1;
    width: 50%;
    height: 100%;
    cursor: pointer;
    & i {
        transition: $transition;
        @include grad($grad, $not-grad);
        font-size: 36px;
        line-height: 36px;
    }
    &.slick-disabled {
        cursor: default;
    }
}

.b-gallery-images__arrow_prev {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    &:not(.slick-disabled) {
        @include hover {
            & i {
                transform: translate3d(-4px, 0, 0);
            }
        }
    }
}

.b-gallery-images__arrow_next {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &:not(.slick-disabled) {
        @include hover {
            & i {
                transform: translate3d(4px, 0, 0);
            }
        }
    }
}

.b-gallery-images__list {
    width: 100%;
    @include xs {
        order: 1;
    }
}

.b-gallery-images__item {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
}

.b-gallery-images__item-inner {
    padding-bottom: 70%;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: block;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}