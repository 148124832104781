.b-cta-image {

	&__image-mobile {
		display: none;

		@include mobile480 {
			display: block;
			margin-bottom: 30px;
		}
	}
}

.b-cta-image__inner {}

.b-cta-image__row {
    @include flex-row;
    @include row;
    align-items: center;

	&_sm {
		@include sm {
			justify-content: center;
		}
	}
}

.b-cta-image__image {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 0 auto;

	@include laptop1350 {
		margin: 0 auto 20px auto;
	}

	@include mobile480 {
		display: none;
	}

    & img {
        display: block;
        max-width: 100%;

        @include sm {
            //margin-top: -50px;
            //margin-bottom: -25px;
        }
    }
}

.b-cta-image__content {
    @include col(8);
    @include col-sm(12);
	margin: 0 0 0 auto;
	max-width: 813px;

	@include laptop1350 {
		margin: 0 auto;
	}

	&_sm {
		@include sm {
			text-align: center;
		}

		@include mobile480 {
			text-align: start;
		}
	}
}

.b-cta-image__title {
    font-size: 48px;
    line-height: 71px;
    color: $darkColor;
    // font-weight: 900;
    font-weight: 600;
    //text-transform: uppercase;
    margin-bottom: 30px;
    @include lg {
        font-size: 34px;
        line-height: 52px;
    }
    @include md {
        font-size: 28px;
        line-height: 50px;
    }
    @include sm {
        font-size: 24px;
        //line-height: 40px;
    }
    @include xs {
        margin-bottom: 15px;
    }

	@include mobile480 {
		max-width: 230px;
		font-size: 24px !important;
		line-height: 1.2;
	}

    @include h_inh;

	&_white {
		color: #fff;
		font-size: 60px;
	}
}

.b-cta-image__text {
	@include fz(23px, 32px);

    font-weight: 500;
    margin-bottom: 30px;
    @include lg {
        font-size: 20px;
        line-height: 28px;
    }
    @include md {
        font-size: 16px;
        line-height: 24px;
    }
    @include sm {
        font-size: 14px;
        line-height: 22px;
    }
    @include xs {
        margin-bottom: 25px;
    }

	@include mobile480 {
		max-width: 216px;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.25;
		margin-bottom: 30px;
	}
}

.b-cta-image__button {
	position: relative;
	top: 18px;
	padding: 15px 52px 16px 48px;

	@include laptop1350 {
		top: auto;
	}
}
