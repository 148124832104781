/*Admin bar*/
.admin-bar {
	.b-header.active {
		top: 32px;
		z-index: 10;
		@include xs {
			top: 0;
		}
	}

	.b-submenu.active {
		margin-top: 32px;
	}
}

/*img*/
img.alignright {
	float: right;
	margin: 0 0 1em 1em
}

img.alignleft {
	float: left;
	margin: 0 1em 1em 0
}

img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto
}

a img.alignright {
	float: right;
	margin: 0 0 1em 1em
}

a img.alignleft {
	float: left;
	margin: 0 1em 1em 0
}

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto
}

/*Contact form 7*/
.wpcf7 {
	@extend .b-first-form;
}

.wpcf7 form .wpcf7-response-output {
	margin: 0 20px 20px;
	@include fz(14px, 22px);
	@include xs {
		margin: 0 10px 20px;
	}
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted.b-contacts__form .wpcf7-response-output {
	border-color: #ff9066;
}

form.invalid .wpcf7-not-valid-tip {
	color: #ff4600;
	@include fz(12px, 16px);
}

form.invalid .b-first-form__header .wpcf7-not-valid-tip {
	color: #ff954a;
}

form.invalid .b-first-form__header select + .wpcf7-not-valid-tip {
	margin-top: 5px;
}

div.wpcf7 .b-button_grad.b-first-form__button + .ajax-loader {
	position: absolute;
	margin-left: -16px;
	background-image: url("../img/ajax-loader.gif");
	right: -32px;
	top: 50%;
	transform: translateY(-50%);
}

/*b-table_standard-width-cols*/
.b-table_standard-width-cols {
}

.b-table_standard-width-cols table {
}

.b-table_standard-width-cols table tr {
}

.b-table_standard-width-cols table tr td {
}

@media (min-width: 960px) {
	.b-table_standard-width-cols table tr td:not(:first-child) {
		width: 25%;
	}
}