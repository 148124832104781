.b-soc-link {
    text-decoration: none;
    padding: 12px;
    cursor: pointer;
    display: inline-flex;
}

.b-soc-link_light {
    color: $lightColor;
    @include fz(24px, 36px);
}