.b-small-title {
    @include fz(18px, 24px);
    // font-weight: 900;
    font-weight: 600;
    @include h_inh;
}

.b-small-title_upper {
    text-transform: uppercase;
}

.b-small-title_center {
    text-align: center;
}