/*  ---------------------------------------------------------------------------
    Template for connecting fonts
    ---------------------------------------------------------------------------
    100 - Thin
    200 - Extra Light (Ultra Light)
    300 - Light
    400 - Regular (Normal)
    500 - Medium
    600 - Semi Bold (Demi Bold)
    700 - Bold
    800 - Extra Bold (Ultra Bold)
    900 - Black (Heavy)  */

// Roboto