.b-pagination {
    @include flex-row;
    display: inline-flex;
    border-radius: 5px;
    @include flex-center;
    position: relative;
    padding: 1px;
    background: $grad;
    // &:before {
    //     background: $grad;
    //     content: "";
    //     border-radius: 5px;
    //     position: absolute;
    //     width: calc(100% + 2px);
    //     height: calc(100% + 2px);
    //     z-index: 1;
    // }
    & ul {
        z-index: 2;
        padding: 0;
        margin: 0;
        @include flex-row;
        @include flex-center;
        background-color: #fff;
        border-radius: 5px;
        & li {
            padding: 0;
            margin: 0;
            width: 50px;
            height: 35px;
            @include flex-row;
            @include flex-center;
            position: relative;
            cursor: pointer;
            &:before {
                display: none;
            }
            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    opacity: 0.5;
                    width: 1px;
                    height: 50%;
                    transition: $transition;
                    background: $grad;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
            &.active {
                color: $lightColor;
                background: $grad;
                border-radius: 4px;
                &:after {
                    display: none;
                }

            }
            & span {
                transition: $transition;
            }
            @include hover {
                & span {
                    transform: translate3d(0, -3px, 0);
                }
            }
            & a {
                transition: $transition;
                display: block;
                width: 100%;
                height: auto;
                text-align: center;
                line-height: 35px;
                vertical-align: middle;
                color: $darkColor;

            }
            &.active a {
                color: $lightColor;
            }
            @include hover {
                & a {
                    transform: translate3d(0, -3px, 0);
                }
            }
        }
    }
}

.screen-reader-text {
	width: 0;
	height: 0;
	overflow: hidden;
	padding: 0;
	margin: 0;
}

.nav-links {
	display: flex;
	justify-content: center;
	margin-top: 10px;

	.page-numbers{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		color: #3C70DE;
		background-color: #fff;
		border:1px solid #3C70DE;
		font-style: normal;
		font-weight: bold;
		border-radius: 50%;
		@include fz(12px,14px);

		&:not(:last-child) {
			margin-right: 4px;
		}

		&:hover ,
		&.current {
			color: #fff;
			background-color: #3C70DE;

			svg path{
				fill: #fff;
			}
		}

		&.prev {
			svg {
				transform: scale(-1 ,1);
			}
		}
	}
}