.b-video {}

.b-video__inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 55%;

    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


}

.b-video__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.b-video__wrapper {
	&.b-section_padding-norm {
		@include tb {
			padding-top: 0;
			padding-bottom: 20px;
		}
	}
}

.video-modal {
	.b-video__inner {
		padding-bottom: 0;

		.b-video__preview {
			position: relative;

			img {
				max-width: 1024px;
			}
		}
	}
}
