.b-mobile-menu-trigger {
    cursor: pointer;
	padding: 10px 0;
    @include flex-row;
    @include flex-center;
    position: relative;
    & i {
        // @include grad($grad, $not-grad);
        color: $orangeColor;
        @include fz(32px, 32px);
        transition: $transition;
        &:nth-child(2) {
            position: absolute;
            @include fz(44px, 44px);
            opacity: 0;
        }
    }
    &.active {
        & i {
            &:nth-child(1) {
                opacity: 0;
            }
            &:nth-child(2) {
                opacity: 1;
            }
        }
    }
}

.b-mobile-menu-icon  {
	width: 39px;
	height: 39px;
	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxOSIgY3k9IjE5IiByPSIxOSIgZmlsbD0iIzNDNzBERSIvPjxwYXRoIGQ9Ik05LjY3Nzk3IDE0LjM1NTlIMjguMzIyQzI4LjY5NDkgMTQuMzU1OSAyOSAxNC4wNTA4IDI5IDEzLjY3OEMyOSAxMy4zMDUxIDI4LjY5NDkgMTMgMjguMzIyIDEzSDkuNjc3OTdDOS4zMDUwOCAxMyA5IDEzLjMwNTEgOSAxMy42NzhDOSAxNC4wNTA4IDkuMzA1MDggMTQuMzU1OSA5LjY3Nzk3IDE0LjM1NTlaIiBmaWxsPSJ3aGl0ZSIvPjxwYXRoIGQ9Ik0xOC44MzA1IDE5Ljc3OThIMjguMzIyQzI4LjY5NDkgMTkuNzc5OCAyOSAxOS40NzQ3IDI5IDE5LjEwMThDMjkgMTguNzI4OSAyOC42OTQ5IDE4LjQyMzggMjguMzIyIDE4LjQyMzhIMTguODMwNUMxOC40NTc2IDE4LjQyMzggMTguMTUyNSAxOC43Mjg5IDE4LjE1MjUgMTkuMTAxOEMxOC4xNTI1IDE5LjQ3NDcgMTguNDU3NiAxOS43Nzk4IDE4LjgzMDUgMTkuNzc5OFoiIGZpbGw9IndoaXRlIi8+PHBhdGggZD0iTTkuNjc3OTcgMjUuMjAzM0gyOC4zMjJDMjguNjk0OSAyNS4yMDMzIDI5IDI0Ljg5ODMgMjkgMjQuNTI1NEMyOSAyNC4xNTI1IDI4LjY5NDkgMjMuODQ3NCAyOC4zMjIgMjMuODQ3NEg5LjY3Nzk3QzkuMzA1MDggMjMuODQ3NCA5IDI0LjE1MjUgOSAyNC41MjU0QzkgMjQuODk4MyA5LjMwNTA4IDI1LjIwMzMgOS42Nzc5NyAyNS4yMDMzWiIgZmlsbD0id2hpdGUiLz48L3N2Zz4=) center center no-repeat;

}
