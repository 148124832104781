.b-table-summary {
    width: 100%;
    border-collapse: collapse;
    & td {
        padding: 5px;
        border: 1px solid #dadada;
    }
    & td:nth-child(2), & td:nth-child(3) {
        white-space: nowrap;
    }
}