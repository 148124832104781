$columns: 12;
$br1920: 1920px;
$br1750: 1750px;
$br1700: 1700px;
$br1600: 1600px;
$br1470: 1470px;
$br1440: 1440px;
$br1350: 1350px;
$br1250: 1250px;
$br1199: 1199px;
$br1170: 1170px;
$br1024: 1024px;
$br1000: 1000px;
$br990: 990px;
$br960: 960px;
$br850: 850px;
$br800: 800px;
$br780: 780px;
$br670: 670px;
$br590: 590px;
$br560: 560px;
$br480: 480px;
$br440: 440px;
$br375: 375px;
$br320: 320px;
$offset: 15px;
$fz_radio_normal: 1;
$fz_radio_laptop: 0.95;
$fz_radio_lg: 0.9;
$fz_radio_md: 0.85;
$fz_radio_sm: 0.8;
$fz_radio_xs: 0.9;
@mixin wrapper() {
    max-width: calc(#{$br1440} + (#{$offset} * 2));
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $offset;
    padding-right: $offset;

	@include xs-mobile {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@mixin wrapper-small() {
    max-width: calc(#{$br1440} + (#{$offset} * 2));
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $offset;
    padding-right: $offset;
}

@mixin row() {
    margin: $offset * -1;
    width: calc(100 * 0.9999% + (#{$offset} * 2));
}

@mixin col($number) {
    width: calc(((100% / #{$columns}) * #{$number * 0.9999}) - #{$offset} * 2);
    margin: $offset;
}

@mixin col-laptop($number) {
    @include laptop() {
        width: calc(((100% / #{$columns}) * #{$number * 0.9999}) - #{$offset} * 2);
    }
}

@mixin col-lg($number) {
    @include lg() {
        width: calc(((100% / #{$columns}) * #{$number * 0.9999}) - #{$offset} * 2);
    }
}

@mixin col-md($number) {
    @include md() {
        width: calc(((100% / #{$columns}) * #{$number * 0.9999}) - #{$offset} * 2);
    }
}

@mixin col-sm($number) {
    @include sm() {
        width: calc(((100% / #{$columns}) * #{$number * 0.9999}) - #{$offset} * 2);
    }
}

@mixin col-xs($number) {
    @include xs() {
        width: calc(((100% / #{$columns}) * #{$number * 0.9999}) - #{$offset} * 2);
    }
}

@mixin col-mobile($number) {
    @include mobile() {
        width: calc(((100% / #{$columns}) * #{$number * 0.9999}) - #{$offset} * 2);
    }
}

@mixin flex-row() {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@mixin flex-colm() {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

@mixin flex-center {
    justify-content: center;
    align-items: center;
}

@mixin offset-left($number) {
    margin-left: calc((100% / #{$columns}) * #{$number});
}

@mixin offset-left-laptop($number) {
    @include laptop() {
        margin-left: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-left-lg($number) {
    @include lg() {
        margin-left: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-left-md($number) {
    @include md() {
        margin-left: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-left-sm($number) {
    @include sm() {
        margin-left: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-left-xs($number) {
    @include xs() {
        margin-left: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-left-mobile($number) {
    @include mobile() {
        margin-left: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-right($number) {
    margin-right: calc(((100% / #{$columns}) * #{$number}) + #{$offset});
}

@mixin offset-right-laptop($number) {
    @include laptop() {
        margin-right: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-right-lg($number) {
    @include lg() {
        margin-right: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-right-md($number) {
    @include md() {
        margin-right: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-right-sm($number) {
    @include sm() {
        margin-right: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-right-xs($number) {
    @include xs() {
        margin-right: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin offset-right-mobile($number) {
    @include mobile() {
        margin-right: calc((100% / #{$columns}) * #{$number});
    }
}

@mixin fz($fz, $lh) {
    font-size: #{$fz};
    line-height: #{$lh};
    @media screen and (max-width: $br1440) {
        font-size: calc(#{$fz} * #{$fz_radio_laptop});
        line-height: calc(#{$lh} * #{$fz_radio_laptop});
    }
    @media screen and (max-width: $br1170) {
        font-size: calc(#{$fz} * #{$fz_radio_lg});
        line-height: calc(#{$lh} * #{$fz_radio_lg});
    }
    @media screen and (max-width: $br960) {
        font-size: calc(#{$fz} * #{$fz_radio_md});
        line-height: calc(#{$lh} * #{$fz_radio_md});
    }
    @media screen and (max-width: $br780) {
        font-size: calc(#{$fz} * #{$fz_radio_sm});
        line-height: calc(#{$lh} * #{$fz_radio_sm});
    }
    @media screen and (max-width: $br560) {
        font-size: calc(#{$fz} * #{$fz_radio_xs});
        line-height: calc(#{$lh} * #{$fz_radio_xs});
    }
}


@mixin xl1920() {
	@media screen and (max-width: $br1920) {
		@content;
	}
}


@mixin xl1750() {
	@media screen and (max-width: $br1750) {
		@content;
	}
}

@mixin xl1700() {
    @media screen and (max-width: $br1700) {
        @content;
    }
}

@mixin xl() {
    @media screen and (max-width: $br1600) {
        @content;
    }
}


@mixin laptop1470() {
    @media screen and (max-width: $br1470) {
        @content;
    }
}

@mixin laptop() {
    @media screen and (max-width: $br1440) {
        @content;
    }
}

@mixin laptop1350() {
	@media screen and (max-width: $br1350) {
		@content;
	}
}

@mixin laptop1250() {
	@media screen and (max-width: $br1250) {
		@content;
	}
}

@mixin laptop1199() {
	@media screen and (max-width: $br1199) {
		@content;
	}
}

@mixin lg() {
    @media screen and (max-width: $br1170) {
        @content;
    }
}

@mixin tb() {
    @media screen and (max-width: $br1024) {
        @content;
    }
}

@mixin md1000() {
	@media screen and (max-width: $br1000) {
		@content;
	}
}

@mixin md990() {
	@media screen and (max-width: $br990) {
		@content;
	}
}

@mixin md() {
    @media screen and (max-width: $br960) {
        @content;
    }
}

@mixin sm850() {
	@media screen and (max-width: $br850) {
		@content;
	}
}

@mixin sm800() {
	@media screen and (max-width: $br800) {
		@content;
	}
}

@mixin sm() {
    @media screen and (max-width: $br780) {
        @content;
    }
}

@mixin ss() {
    @media screen and (max-width: $br670) {
        @content;
    }
}

@mixin xs590() {
	@media screen and (max-width: $br590) {
		@content;
	}
}

@mixin xs() {
    @media screen and (max-width: $br560) {
        @content;
    }
}

@mixin mobile480() {
	@media screen and (max-width: $br480) {
		@content;
	}
}

@mixin mobile() {
    @media screen and (max-width: $br440) {
        @content;
    }
}

@mixin xs-mobile() {
    @media screen and (max-width: $br375) {
        @content;
    }
}

@mixin mobile-320() {
    @media screen and (max-width: $br320) {
        @content;
    }
}

@mixin from($min_width) {
    @media screen and (min-width: $min_width) {
        @content;
    }
}

@mixin to($max_width) {
    @media screen and (max-width: $max_width) {
        @content;
    }
}

@mixin inh() {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

.r-row {
    @include flex-row;
    @include row;
}

.r-col {
    @include col(3);
    @include col-lg(4);
    @include col-md(6);
    @include col-sm(10);
    @include col-xs(11);
    @include col-mobile(12);
    @include sm {
        margin-left: auto;
        margin-right: auto;
    }
    height: 150px;
    background-color: red;
}

@mixin grad($grad, $color) {
    // @supports (-webkit-background-clip: text) {
    // @supports not (-ms-ime-align: auto) {
    //     background: $grad;
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    // }
    // @supports (-ms-ime-align:auto) {
    //     color: $color;
    // }
    color: #3c70de;
}

@mixin hover() {
    transition: $transition;
    &:hover {
        @content;
    }
}

@mixin h_inh() {
    display: block;
    &>h1,
    &>h2,
    &>h3,
    &>h4,
    &>h5,
    &>h6,
    &>a,
    &>span {
        @include inh;
        margin: 0;
        &>h1,
        &>h2,
        &>h3,
        &>h4,
        &>h5,
        &>h6,
        &>a,
        &>span {
            @include inh;
            margin: 0;
        }
    }
}