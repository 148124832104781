.products-section {
	padding: 57px 0 100px;

	@include md {
		padding: 60px 0;
	}

	.products-section__title-wrapper {
		@include mobile {
			padding-right: 0;
		}
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		position: relative;
		left: -74px;

		@include laptop1350 {
			left: auto;
		}

		@include md {
			flex-wrap: wrap;
		}
	}

	&__image-wrapper {
		width: 50%;
		position: relative;
		top: 21px;
		left: -29px;

		@include laptop1350 {
			left: auto;
			top: auto;
		}

		@include md {
			width: 100%;
			top: 0;
			left: 0;
			text-align: center;
		}

		@include sm {
			display: none;
		}


		&_mob {
			display: none;

			@include sm {
				display: block;
				margin-bottom: 40px;
			}
		}
	}

	&__content {
		width: 50%;

		@include md {
			width: 100%;
		}

		@include sm {
			padding: 0 15px;
		}
	}

	&__image {
		position: relative;
		top: 0;
		left: auto;
		right: 0;
		max-width: none;

		@include laptop {
			position: static;
			max-width: 100%;
		}
	}

	&__title {
		margin-bottom: 25px;
		@include fz(56px, 67px);
		margin-top: -9px;
		letter-spacing: 1px;
	}

	&__title-wrapper {
		margin-bottom: 35px;
	}

	&__description {

		strong {
			font-weight: 700;
		}


		p {
			font-weight: 300;
			@include fz(20px, 28px);
			color: #1F1D25!important;
		}
	}
}