.prices-main__all-content-page-wrapper {

	h1.prices-main__title-page.prices-main__title-page-laptop {
		display: block;
		margin-top: 60px;
		margin-bottom: 32px;

		@include mobile480 {
			margin-top: 24px;
		}
	}

	.prices-main__main-content {
		max-width: 1110px;
		width: 100%;
	}

	.prices-main__product-item-name {
		max-width: 100%;

		@include mobile480 {
			width: 100%;
			flex: none;
		}
	}

	.prices-main__item-field {
		@include mobile480 {
			padding: 10px;
			font-size: 14px;
			line-height: 1.28;
		}
	}

	.prices-main__product-item-price,
	.prices-main__buy-item-btn-wrappper {
		@include mobile480 {
			flex: none;
			width: 50%;
			margin-right: 0;
		}
	}

	.prices-main__product-item-price {
		@include mobile480 {
			justify-content: flex-start;
			max-width: 50%;
		}
	}

	.prices-main__buy-item-btn-wrappper {
		@include mobile480 {
			justify-content: flex-end;
		}
	}

	.prices-main__product-item {
		@include mobile480 {
			flex-wrap: wrap;
		}
	}

	.prices-main__buy-item-btn {
		max-width: 98px;
		width: 100%;
		max-height: 40px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		appearance: none;
		margin: 0;
		background: #3C70DE;
		box-shadow: 0 0 20px rgba(60, 112, 222, 0.583642);
		border-radius: 25px;
		color: #FFF;
		padding: 12px 30px;
		cursor: pointer;
		border: 1px solid transparent;
		transition: all .2s ease;

		&:hover  {
			background: #FFF;
			color: #3C70DE;
			border: 1px solid #3C70DE;
		}
	}
}

.page-template-page-template-service-prices-php {
	.b-modal {
		overflow: hidden;
	}
}