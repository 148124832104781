.b-menu {}

.b-menu>ul {
    @include flex-row;
    margin: -8px;
	border-bottom: 1px solid #FF4600;

}

.b-menu>ul>li {
    @include fz(13px, 16px);
    // color: $darkColor;
    color: $blueColor;
    font-weight: 500;
	
    &:before {
        display: none;
    }
	
	&.menu-item-has-children {
		position: relative;

		> a {
			padding-right: 40px;

			@include laptop {
				padding-right: 30px;
			}
		}

		&:after {
			content: '';
			position: absolute;
			width: 10px;
			height: 6px;
			left: auto;
			top: 20px;
			right: 20px;
			background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMjEyNDkgMC4zMTg3OThDMS4wNDA3NCAwLjQ5NTU0OSAwLjk1NTMxNiAwLjcyNDA5OSAwLjk1NTMxNiAwLjk1MjY0OUMwLjk1NTMxNiAxLjE4OTM4IDEuMDQ3MSAxLjQyNjExIDEuMjMwMjEgMS42MDQyMkw0LjIxMTgxIDQuNTA0MDNDNC41NTUzMSA0Ljg1MjA4IDUuMDEzMzIgNS4wNDM4MiA1LjUwMTMyIDUuMDQzODJDNS45ODkzMSA1LjA0MzgyIDYuNDQ3MzIgNC44NTIwOCA2Ljc3ODU2IDQuNTE2NzVMOS43NjgzMyAxLjYwNDIyQzEwLjEyNzcgMS4yNTM0NCAxMC4xMzU1IDAuNjc4MjA3IDkuNzg1MTUgMC4zMTkyNTNDOS40MzQ4MiAtMC4wNDA2MTA3IDguODU5NTkgLTAuMDQ3ODgwNyA4LjUwMDE4IDAuMzAyNDQxTDUuNDkyMjMgMy4yMTQ1MkwyLjQ5NzkxIDAuMzAxOTg3QzIuMTM4MDUgLTAuMDQ4Nzg5NCAxLjU2MjgxIC0wLjA0MTA2NTEgMS4yMTI0OSAwLjMxODc5OFoiIGZpbGw9IiMzQzcwREUiLz48L3N2Zz4=) center center no-repeat;

			@include laptop {
				right: 5px;
			}

		}
	}

    &.active {
        & a,
        & span {
            &:after {
                opacity: 1;
            }
        }
    }
    margin: 0;
}



.b-menu>ul>li>a,
.b-menu>ul>li>span {
    display: block;
    padding: 14px 18px;
	color: #13111C!important;
    @include inh;
    text-transform: uppercase;
    position: relative;
    white-space: nowrap;

	@include laptop {
		padding: 14px 10px;
	}

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transition: $transition;
        top: calc(100% - 2px);
        left: 0;
        height: 4px;
        background: #FF4600;
        opacity: 0;
        @include mobile {
            height: 1px;
        }
    }

    @include hover {
        &:after {
            opacity: 1;
        }
    }
}