.b-footer {

	.b-footer__text {
		p {

			@include mobile480 {
				text-align: start;
				margin-bottom: 18px;
			}
		}
	}
}

.b-footer__row {
	//padding: 15px;
	//background-color: $lightColor;
}

.b-footer__row_big-p {
	padding: 60px 15px;

	@include md {
		padding: 50px 15px;
	}

	@include mobile480 {
		padding-top: 58px;
	}
}

.b-footer__row_bg-dark {
	background: #2E3042;
}

.b-footer__inner {
	@include wrapper;

	@include mobile480 {
		padding-left: 0;
		padding-right: 0;
	}
}

.b-footer__content {
	@include flex-row;
}

.b-footer__block {
	display: inline-flex;
	flex-direction: column;
	padding-bottom: 20px;
	flex-grow: 1;

	&:first-child {
		margin-right: 50px;
	}

	@include to(1590px) {

		&:first-child {
			margin-right: 0;
			//width: 100%;
		}
	}

	@include laptop {

		&:not(:first-child) {
			width: calc(100% / 3);
		}
	}

	@include sm {
		&:not(:first-child) {
			width: calc(100% / 2);
		}
	}
	@include xs {
		&:not(:first-child) {
			width: 100%;
		}
	}
}

.b-footer__block_line {
	position: relative;
	// &:after {
	//     content: '';
	//     position: absolute;
	//     top: 35px;
	//     left: 0;
	//     width: 100%;
	//     height: 1px;
	//     background-color: $lightColor;
	// }
}

.b-footer__logo {
	max-width: 140px !important;
	margin-bottom: 20px;
	display: block;

	@include md {
		margin-left: auto;
		margin-right: auto;
	}

	@include mobile480 {
		margin-left: 0;
		margin-bottom: 0;
	}
}

.b-footer__block-title {
	@include fz(14px, 21px);
	font-weight: 500;
	color: $lightColor;
	margin-bottom: 20px;
	border-bottom: 1px solid $lightColor;
	padding-bottom: 15px;
	@include h_inh;
}

.b-footer__block-list {
	padding-right: 30px;

	& ul {
		& li {
			color: $lightColor;
			@include fz(11px, 16px);
			margin-bottom: 12px;

			&:before {
				display: none;
			}

			font-weight: 500;

			& a {
				@include inh;
				// text-decoration: underline;
				text-decoration: none;
			}
		}
	}
}

.b-footer__text {
	@include flex-row;
	width: calc(100% + 20px);
	margin-left: -10px;
	margin-right: -10px;
	align-items: center;
	justify-content: flex-start;
	padding-left: 10px;

	& p {
		display: inline-block;
		margin: 0;
		padding: 0;
		@include fz(14px, 17px);
		color: #FFFFFF !important;
		text-transform: uppercase;
		//color: $darkColor;
		& a {
			@include inh;
			text-decoration: none;
			white-space: nowrap;
			color: inherit;
			mix-blend-mode: normal;
			opacity: 0.44;
			text-transform: none;

			@include hover {
				text-decoration: none;
				opacity: 0.7;
				//text-decoration-color: $blueColor;
			}
		}

		&:first-child {
			margin-right: 50px;

			@include md {
				margin-right: 0;
			}
		}

		&:nth-child(2) {
			margin-right: 15px;

			@include md {
				margin-right: 0;
			}
		}

		@include md {
			width: 100%;
			text-align: center;
			&:nth-child(2) {
				margin-left: auto;
				margin-right: auto;
			}
			&:nth-child(3) {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	@include sm {
		justify-content: center;
	}
}

.footer {

	&__content {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-bottom: 64px;

		@include md {
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: 30px;
		}

		@include mobile480 {
			margin-bottom: 10px;
		}
	}

	&__menus-box {
		display: flex;
		justify-content: flex-end;
		width: 70%;
		padding-right: 113px;

		@include laptop1350 {
			justify-content: space-between;
		}

		@include tb {
			padding-right: 0;
		}

		@include lg {
			width: 75%;
		}

		@include md {
			width: 100%;
			flex-direction: column;
			align-items: center;
		}

		@include mobile480 {
			display: none;
		}
	}

	&__menu-wrapper {
		//width: calc(33% - 20px);
		&:not(:last-child) {
			margin-right: 46px;

			@include laptop1350 {
				margin-right: 0;
			}
		}

		&:first-child {
			margin-right: 230px;

			@include laptop1350 {
				margin-right: 0;
			}
		}

		@include md {
			width: 100%;
			margin-bottom: 30px;
			text-align: center;
		}

		&:last-child {

			@include md {
				margin-bottom: 0;
			}
		}
	}

	&__menu-caption {
		font-weight: 500;
		font-size: 16px;
		line-height: 1.18;
		text-transform: uppercase;
		color: #FFFFFF !important;
		position: relative;
		padding-left: 7px;
		margin-bottom: 19px;

		@include md {
			width: auto;
			display: inline-block;
		}

		&:before {
			content: '';
			width: 2px;
			height: 12px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			background: #FF4600;
		}
	}

	&__menu-container {
		padding-left: 5px;
	}

	&__menu-nav-list {
		margin: 0;
		list-style: none;

		.menu-item {
			list-style: none;
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				display: none;
			}

			> a {
				font-weight: 300;
				font-size: 15px;
				line-height: 1.06;
				color: #FFFFFF !important;
				mix-blend-mode: normal;
				opacity: 0.51;

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	&__block-wrapper {
		width: 100%;
	}

	&__block {
		width: 20%;
		max-width: 20%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0;

		@include md {
			width: 100%;
			max-width: 100%;
			align-items: center;
			margin-bottom: 30px;
		}
	}

	&__block-description {
		font-weight: 300;
		font-size: 16px;
		line-height: 1.18;
		color: #FFFFFF !important;
		margin-bottom: 30px;
		width: 100%;

		@include md {
			display: none;
		}
	}

	&__block-description-mobile {
		display: none;

		@include mobile480 {
			display: block;
		}
	}
}

.footer__block {

	@include mobile480 {
		align-items: flex-start;
		flex-direction: row;
	}
}