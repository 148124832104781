.b-arrow-button {
    display: inline-flex;
    cursor: pointer;
    padding: 8px 25px;
    border-radius: 5px;
    & img {
        display: block;
        transition: $transition;
        max-width: 60px;
    }
    @include hover {
        & img {
            transform: translate3d(4px, 0, 0);
        }
    }
    @include sm {
        padding: 8px 30px;
        @include hover {
            & img {
                transform: translate3d(2px, 0, 0);
            }
        }
    }
    &>* {
        z-index: 2;
    }
}

.b-arrow-button_border {
    // border: 1px solid red;
    // background-image: url('../img/border-grad-big.png');
    // background-position: 0 0;
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    position: relative;
    background: $grad;
    &:after {
        z-index: 1;
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 4px;
        background-color: #fff;
    }
}

.b-arrow-button_jcc {
    justify-content: center;
}

.b-arrow-button_jc-end {
    justify-content: flex-end;
}