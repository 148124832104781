.b-first-slider {
    position: relative;

    & .slick-track {
        display: flex !important;
    }
    & .slick-slide {
        height: auto !important;
	    margin-top: 0;
	    margin-bottom: 0;
		padding-bottom: 91px;

		@include md {
			padding-bottom: 0;

		}
    }
}

.b-first-slider__inner {}

.b-first-slider__arrows {
    @include flex-row;
    display: inline-flex;
    @include flex-center;
    position: absolute;
    top: -50px;
    right: 0px;
    z-index: 3;
}

.b-first-slider__arrows_not-abs {
    position: relative;
    top: 0;
    left: 0;
}

.b-first-slider__arrows_t0-r0 {
    top: 15px !important;
    right: 0 !important;
}

.b-first-slider__arrows_grad {
    // background-image: url("../img/border-grad.png");
    // background-size: 100% 100%;
    // background-position: 0 0;
    border: 1px solid $orangeColor;
    border-radius: 4px;
    &:after {
        content: "";
        width: 1px;
        height: calc(100% - 14px);
        position: absolute;
        top: 7px;
        left: calc(50% - 0.5px);
        // background: $grad;
        background-color: $orangeColor;
    }
}

.b-first-slider__arrows_relative {
    position: relative;
    top: -15px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}

.b-first-slider__arrow {
    width: 36px;
    height: 36px;
    @include flex-row;
    @include flex-center;
    font-size: 36px;
    & i {
        // @include grad($grad, $not-grad);
        color: $orangeColor;
    }
    margin: 7px;
    cursor: pointer;
}

.b-first-slider__arrow_prew {
    @include hover {
        transform: translate3d(-3px, 0, 0);
    }
}

.b-first-slider__arrow_next {
    @include hover {
        transform: translate3d(3px, 0, 0);
    }
}

.b-first-slider__row {
    @include flex-row;
    justify-content: space-between;
    align-items: center;

    @include xs {
        & .b-title {
            width: 100%;
            margin-bottom: 15px;
        }
        & .b-first-slider__arrows {
            margin-left: auto;
        }
    }

    & .b-title {
        width: calc(100% - 100px - 15px);
        text-align: left;
    }
}

.b-first-slider__list {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
	flex-wrap: wrap;
}

.b-first-slider__item {
    margin: 15px;
}

// slick
.b-first-slider__list {
    .slick-list {
        margin-bottom: 0;
    }
    .slick-dots {
        @include flex-row;
        @include flex-center;
	    justify-content: flex-start;
	    padding: 0 15px;
		position: relative;
		top: -31px;
		left: -24px;

	    @include md {
		    justify-content: center;
			position: static;
	    }

		@include mobile {
			position: relative;
			top: -21px;
			left: -100px;
	    }

		@include xs-mobile {
			position: relative;
			top: -21px;
			left: -56px;
	    }

        & li {
            cursor: pointer;
            list-style-type: none;
            list-style: none;
            &:before {
                display: none;
            }
            @include flex-row;
            @include flex-center;
            & button {
                cursor: pointer;
	            position: relative;
	            padding: 0;
	            appearance: none;
                width: 40px;
                height: 2px;
                border: none;
                border-radius: 0;
                // background: $grad;
                background-color: #FF4600;
                color: transparent;
                margin: 0 5px;
                opacity: 1;
                transition: $transition;

	            @include hover {
		            background-color: rgba(255, 70, 0, 0.31);

	            }

            }

            &.slick-active {
                & button {
                    //margin-left: 6px;
	                background-color: rgba(255, 70, 0, 0.31);
                    opacity: 1;

                    //transform: scale(1.6);

	                &:before {
		                content: '';
		                width: 10px;
		                height: 2px;
		                position: absolute;
		                top: 0;
		                left: 0;
		                background-color: #FF4600;

	                }
                }
            }

        }
    }
}

.b-first-slider__list_dif {
	.slick-dots {
		& li {
			& button {
				//background-color: #3C70DE;
			}

			&.slick-active {
				& button {
					//background-color: rgba(183, 104, 203, 0.31);
					opacity: 1;

					&:before {
						//background-color: #B768CB;
					}
				}
			}
		}
	}
}

.b-first-slider__arrow {
    &.slick-disabled {
        cursor: default;
        opacity: 0.6;
        pointer-events: none;
    }
}

.reviews {

	&__slider-title {
		margin-bottom: 0;
	}

	&.b-section {
		padding: 75px 0 60px;

		@include sm {
			padding: 20px;
		}
	}

	&__list {
		flex-wrap: wrap;

	}

	.slick-dots {
		display: none!important;
	}

	.slick-slide {
		border: 1px solid #3C70DE;
		box-sizing: border-box;
		box-shadow: none;
	}

	.b-first-slider__item {
		box-shadow: none;
	}

	&__item {
		padding: 28px 30px 45px;

		@include sm {
			padding: 20px 20px 25px;
		}

		@include mobile {
			margin: 0 15px;
		}
	}

	&__item-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		@include mobile {
			align-items: center;
		}
	}

	&__image-wrapper {
		padding-bottom: 0;
		width: 100%;
		max-width: 180px;
		height: 100px;
		overflow: hidden;
		position: relative;

		@include sm {
			max-width: 130px;
			height: 70px;
		}
	}

	&__item-main {
		padding: 0;
		width: 100%;
		margin-bottom: 28px;

		@include sm {
			padding: 0;

		}
	}

	&__item-footer {
		position: relative;
		z-index: 2;
		padding: 0 0 0 5px;
	}

	&__item-image {
		position: absolute;
		width: 100%;
		height: auto;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&__arrows {
		width: 100%;
		display: flex;
		justify-content: center;
		border: none;
		padding-top: 50px;

		@include sm {
			padding-top: 35px;
		}

		&:after {
			display: none;
		}
	}

	&__arrow {
		width: 50px;
		height: 50px;
		display: inline-block;
		border-radius: 50%;
		margin: 0 5px;
		border: 1px solid #FF4600;
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC41Njg4OCAwLjE2MDg0NEM4LjMzNTgzIC0wLjA2MTExNDggNy45NjYyOSAtMC4wNTIzNjQ4IDcuNzQ0MzMgMC4xODA5NjlDNy41MjIwOCAwLjQxNDMwMiA3LjUzMTQyIDAuNzgzNTUyIDcuNzY0NDYgMS4wMDU4TDEyLjIzOTIgNS4yNDk4NEgwLjU4MzMzM0MwLjI2MTMzMyA1LjI0OTg0IDAgNS41MTExOCAwIDUuODMzMThDMCA2LjE1NTE4IDAuMjYxMzMzIDYuNDE2NTEgMC41ODMzMzMgNi40MTY1MUgxMi4yMjA1TDcuNzY0NDYgMTAuNjYwOEM3LjUzMTEzIDEwLjg4MzEgNy41MjIwOCAxMS4yNTIzIDcuNzQ0MzMgMTEuNDg1N0M3Ljg1ODk2IDExLjYwNTggOC4wMTI2NyAxMS42NjY1IDguMTY2NjcgMTEuNjY2NUM4LjMxMTMzIDExLjY2NjUgOC40NTYgMTEuNjEzMSA4LjU2ODg4IDExLjUwNTVMMTMuNjU4MiA2LjY1ODAxQzEzLjg3ODcgNi40Mzc4IDE0IDYuMTQ0OTcgMTQgNS44MzMxOEMxNCA1LjUyMTM5IDEzLjg3ODcgNS4yMjg1NSAxMy42NDggNC45OTg0M0w4LjU2ODg4IDAuMTYwODQ0WiIgZmlsbD0iI0ZGNDYwMCIvPjwvc3ZnPg==)center center no-repeat;
		transition: opacity 0.3s;

		@include hover {
			opacity: 0.6;
		}
	}

	&__arrow-prev {
		transform: rotate(180deg);
	}

	&__bottom-content {
		text-align: center;
		padding-top: 40px;

		@include sm {
			padding-bottom: 57px;
		}
	}

	&__bottom-content-title {
		font-weight: 500;
		@include fz(22px, 26px);
		text-align: center;
		text-transform: uppercase;
		color: #1F1D25!important;
		margin-bottom: 15px;
	}

	&__bottom-content-img-wrapper {
		margin-bottom: 20px;
	}

	&__title-wrapper {
		margin-bottom: 44px;
		margin-left: 0;
		margin-right: 0;

		@include sm {
			margin-bottom: 40px;
		}

		.b-title {
			width: 100%;
		}
	}
}

.single-products {
	.reviews {
		padding-bottom: 0;
	}
}