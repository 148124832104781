.b-checkbox {
    @include flex-row;
    cursor: pointer;
}

.b-checkbox__input {
    display: none;
}

.b-checkbox__rect {
    // background-image: url('../img/checkbox.png');
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    border: 1px solid $orangeColor;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    position: relative;
    overflow: hidden;
    @include flex-row;
    @include flex-center;
    &:after {
        // content: '\2713';
        position: absolute;
        // width: 100%;
        // height: 100%;
        // @include flex-row;
        // @include flex-center;
        // transition: $transition;
        // transform: translate3d(0, 35%, 0);
        // 
        // @include grad($grad, $not-grad);
        color: #fff;
        opacity: 0;
        font-size: 12px;
        content: "\f00c";
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
}

.b-checkbox__text {
    user-select: none;
    width: calc(100% - 18px - 12px);
    @include fz(15px, 18px);
	font-family: 'SF Pro Display', sans-serif;
	font-style: normal;
	font-weight: 300;
	color: #1F1D25;
}

.b-checkbox__hint {}

.b-checkbox__error {}

// 
.b-checkbox__input:checked~.b-checkbox__rect {
	background-color: $orangeColor;
    &:after {
        // transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}