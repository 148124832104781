.b-main{
    width: 100%;

	&__section_portfolio {
		.b-section__inner {
			padding-left: 34px;
		}
	}
}

.b-main__section{}