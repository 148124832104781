.scroll-bar {
    background: $lightColor !important;
    width: 4px !important;
    left: -1px !important;
}

.scrollbar-inner>.scroll-element.scroll-y {
    width: 2px;
}

.scrollbar-inner>.scroll-element .scroll-element_track,
.scrollbar-inner>.scroll-element .scroll-bar {
    opacity: 1;
}

.scroll-element_outer {
    overflow: visible !important;
}

.mCSB_dragger_bar {
	background-color:  #ff4600!important;
	width: 4px!important;
	border-radius: 0!important;
}

.mCSB_draggerRail {
	background-color: rgba(60, 112, 222, 0.24)!important;
	width: 2px!important;
}