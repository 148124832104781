.b-header {
	background-color: #FFF;
	mix-blend-mode: normal;
	box-shadow: 0 0 30px rgba(35, 62, 119, 0.195622);
	position: relative;
	width: 100%;
	min-width: 320px;
	z-index: 6;

	@include sm {
		background-color: #fff;
	}

	@include xs {
		position: relative;
		width: 100vw;
		z-index: 12;
		animation: header 0.4s linear;
		top: 0;
		left: 0;
		right: 0;
	}

	&.active {
		position: fixed;
		background-color: #ffffff;
		width: 100%;
		z-index: 10;
		animation: header 0.4s linear;
		top: 0;
		left: 0;
		right: 0;
	}


	&__mobile-menu-trigger {
		order: 2;
	}
}

@keyframes header {
	0% {
		transform: translate3d(0, -100%, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}

.b-header__inner {
	@include wrapper;
	position: relative;
}

.b-header__content {
	@include flex-colm;
}

.b-header__row {
	position: relative;
	width: 100%;
	@include flex-row;
	align-items: center;

	&_pt {
		padding-top: 8px;
	}

	&_pb {
		padding-bottom: 12px;
	}

	@include sm850 {
		&:first-child {
			display: none;
		}
	}

	&.active {
		#searchform- {
			position: relative;
			z-index: 1;
			overflow: hidden;

			@include sm {
				background: #EEF0F7;
				border-radius: 20px;
				height: 40px;
				width: 156px;
			}

			input[type="text"] {
				border: none;
				width: 110px;

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					transition: background-color 5000s ease-in-out 0s;
				}
			}

			button[type="submit"] {
				position: absolute;
				right: 0;
				top: 0;
				z-index: 100;
			}
		}

		.b-header__multilang {
			display: none;
		}

		.header__button-mobile-wrapper {
			.b-button_orange {
				display: none;
			}
		}
	}

}

.b-header__wrap-search {
	.b-header__multilang{
		display: none;

		@include sm {
			display: block;
		}

	}
}

.b-header__contacts {
	@include flex-row;
	align-items: baseline;
	position: relative;
	right: -33px;
	//margin-right: auto;

	@include lg {
		right: auto;
		//margin-bottom: 20px;
	}

	@include md {
		flex-direction: column;
	}
}

.header__link-icon {
	&:not(:last-child) {
		margin-right: 40px;

		@include md {
			margin-right: 20px;
		}
	}

	&_phone {
		&:before {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuODY3NjMgOC4wODkyNkw3LjYyNDQ5IDYuNDgwNThDNy41MiA2LjQwNTYzIDcuMzgwNTYgNi40MDExOSA3LjI3MTUyIDYuNDY5MzVMNi4wMDc3MSA3LjI1OTA4QzUuOTM5NzQgNy4yNzIxMSA1LjY0NDI3IDcuMjE2OCA1LjA3Mjk4IDYuODAzMjRDNC41OTI2NiA2LjQ1NTU2IDQuMDc3OTIgNS45Njg3NCAzLjY5NjA5IDUuNTAxMDRDMy4xMzAzIDQuODA4MDMgMy4wODgxMyA0LjQ1ODYxIDMuMTE5MTggNC4zODAyOEwzLjkxMjY3IDMuMTEwNDhDMy45ODA4MyAzLjAwMTQxIDMuOTc2MzkgMi44NjIgMy45MDE0NCAyLjc1NzQ5TDIuMjkyNzEgMC41MTQzNjZDMi4yMzg0MiAwLjQzODY3NiAyLjE1MzYyIDAuMzkwNjY4IDIuMDYwOCAwLjM4MzA3NkMxLjk2ODA2IDAuMzc1NDg0IDEuODc2NDkgMC40MDkwNjggMS44MTA2MSAwLjQ3NDkyNEwwLjY1MzE3MyAxLjYzMjI4Qy0wLjAwMjQ3NDMxIDIuMjg3ODggLTAuMTg0OTg2IDMuMjg2MTggMC4xOTkwMDggNC4xMTY0NUMwLjcxMjQ3MyA1LjIyNjYgMS4zNzkwNyA2LjI1MjQ1IDIuMTgwMzEgNy4xNjU1MkMzLjMyNTY5IDguNDcwNzYgNC43MTAyIDkuNDk1ODEgNi4yOTU0MyAxMC4yMTIyQzcuMTA3MDMgMTAuNTc5IDguMDg4OTYgMTAuMzg5NSA4LjczODQ5IDkuNzM5OTVMOS45MDcwNyA4LjU3MTM5QzkuOTcyOTMgOC41MDU1MSAxMC4wMDY1IDguNDE0MDQgOS45OTg5NCA4LjMyMTJDOS45OTEzMyA4LjIyODM2IDkuOTQzMzIgOC4xNDM1NSA5Ljg2NzYzIDguMDg5MjZaIiBmaWxsPSIjM0M3MERFIi8+Cjwvc3ZnPgo=);
		}
	}

	&_email {
		&:before {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjY2MzU2IDQuNDMwNEwxMC41NDIgMS43Njk0NUMxMC4yMjM1IDAuNzIxNTM1IDkuMjU4NzkgMC4wMDM4NTM4NyA4LjE2MzU2IDBIMy4xNjM1NkMyLjA2ODMyIDAuMDAzODUzODcgMS4xMDM2NSAwLjcyMTUzNSAwLjc4NTE1NiAxLjc2OTQ1TDUuNjYzNTYgNC40MzA0WiIgZmlsbD0iIzNDNzBERSIvPgo8cGF0aCBkPSJNNS45MDM1NiA1LjQzODk0QzUuNzU0MjcgNS41MjAzMiA1LjU3Mzg2IDUuNTIwMzIgNS40MjQ1NiA1LjQzODk0TDAuNjY0MDYyIDIuODQyMjlWNi40OTk5OUMwLjY2NTY4OCA3Ljg4MDAyIDEuNzg0MDIgOC45OTgzNiAzLjE2NDA2IDguOTk5OTlIOC4xNjQwNkM5LjU0NDEgOC45OTgzNiAxMC42NjI0IDcuODgwMDIgMTAuNjY0MSA2LjQ5OTk5VjIuODQyMjlMNS45MDM1NiA1LjQzODk0WiIgZmlsbD0iIzNDNzBERSIvPgo8L3N2Zz4K);
		}
	}
}


.b-header__lang-mob {
	display: none;

	.b-header__multilang {
		width: 38px;
		height: 23px;
		border-radius: 22.5px;
		background-color: #EEF0F7;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		@include fz(11px, 21px);

		&:hover {
			border: 1px solid #3C70DE;
		}
	}

	.b-multilang__title span {
		font-size: 11px;
	}
	.b-multilang__inner {
		width: 38px;
	}

	.b-multilang__list {
		padding: 5px;
		width: 100%;
		left: 0;
		top: 90%;

		&:before {
			top: -15px;
		}
	}

	@include sm {
		display: block;
		margin-right: 20px;
	}
}

.b-header__sab {
	margin-left: 30px;
	width: 170px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include lg {
		display: none;
	}

	@include tb {
		margin-top: 15px;
		margin-bottom: 15px;
		margin-left: 0;
	}
}

a.b-header__logo {
	width: 160px;
	max-width: 100%;
	margin-right: 70px;
	height: 76px;
	margin-top: -10px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include laptop {
		margin-top: 0;
		margin-right: 40px;
		width: 140px;
	}

	@include lg {
		margin-right: 20px;
	}

	@include mobile480 {
		width: 82px;
		margin-right: auto;
	}

	svg {
		width: inherit;
		height: inherit;

		@include mobile480 {
			width: 80px;
		}
	}
}

.b-header__menu {
	margin-right: auto;
	margin-top: auto;
	@include lg {
		width: 100%;
		order: 2;
		padding-top: 10px;
	}
	@include sm850 {
		display: none;
	}
}

.b-header__search {
	@include flex-row;
	background: #EEF0F7;
	border-radius: 20px;
	position: relative;
	margin-right: 37px;
	cursor: pointer;
	top: -3px;
	left: 3px;
	height: 40px;
	//display: none;

	@include lg {
		top: 0;
	}

	@include laptop {
		margin-right: 20px;
		//top: -3px;
		left: 0;
	}

	@include sm {
		//display: block;
	}

	@include mobile480 {
		top: 0;
		display: none;
	}

	form {
		transition: width .3s;
		width: auto;
		display: flex;
	}

	& input[type="text"] {
		//position: absolute;
		//top: 0;
		//right: 100%;
		height: 100%;
		width: 0;
		border: none;
		padding: 0;
		z-index: 2;

		@include sm {
			//left: 10px;
		}

		@include xs {
			//top: 100%;
			//right: -100px;
		}
	}

	& button[type="submit"] {
		border: none;
		background: none;
		cursor: pointer;
		//display: none;
	}

	& i {
		pointer-events: none;
		width: 40px;
		height: 40px;
		font-size: 24px;
		line-height: 24px;
		// @include grad($grad, $not-grad);
		color: #31303A;
		@include flex-row;
		align-items: center;
		justify-content: center;
	}

	&.active {
		margin-right: 10px;
		display: block;
		width: auto;

		@include xs-mobile {
			display: block;
		}

		form {
			position: absolute;
			top: -1px;
			right: 0;
			overflow: hidden;
			border-radius: 20px;
			width: 280px;

			@include xs {
				width: 156px;
			}
		}

		& input {
			width: 100%;
			height: 100%;
			padding: 12px;
			//border: 1px solid #fafaff;
			background: #EEF0F7;
			border-radius: 20px 0 0 20px;

			//@include xs {
			//	width: 156px;
			//	right: 36px;
			//	top: 0;
			//	align-content: normal;
			//}
		}

		button[type="submit"] {
			display: block;
			background-color: #EEF0F7;
			border-radius: 0 20px 20px 0;
		}
	}

	// &:hover {
	//     & input {
	//         width: 280px;
	//         padding: 12px;
	//         border: 1px solid #fafaff;
	//         @include xs {
	//             width: calc(100vw - 50px);
	//         }
	//     }
	// }
	@include lg {
		margin-left: auto;
	}
}

.b-header__mobile-menu-trigger {
	display: none !important;
	@include sm850 {
		display: flex !important;
	}
}

.b-header__multilang {
	border: 1px solid #3C70DE;
	box-sizing: border-box;
	border-radius: 22.5px;
	transition: background 0.3s;
	height: 100%;


	@include sm {
		margin-right: 20px;
		max-width: 38px;
		width: 100%;
		max-height: 23px;
		height: 23px;
		border: none;
		background: #EEF0F7;
		font-size: 11px;
		line-height: 13px;
	}

	&.active {
		@include sm {
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			border-radius: 11.5px;
		}
	}


	@include hover {
		background: #ffffff;
	}

	.b-multilang__title {

		@include sm {
			span {
				font-size: 11px;
				line-height: 1.18;
				color: #3C70DE;
			}
		}
	}
}

.header {

	&__menu-wrapper {
		display: flex;
		align-items: center;
		height: 36px;
	}

	&__button-wrapper {
		position: relative;
		top: 0;

		@include sm850 {
			margin-right: 20px;
		}

		@include xs {
			display: none;
		}
	}

	&__top-menu-container {
		background: #EEF0F7;
		border-radius: 22.5px;
		overflow: hidden;
		padding: 10px 6px;
		margin-right: 8px;
		height: 100%;
		display: flex;
		align-items: center;

	}

	&__top-section {
		justify-content: space-between;
		padding-bottom: 14px;

		@include tb {
			//flex-direction: column-reverse;
		}
	}

	&__top-menu {
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.menu-item {
			list-style: none;
			margin: 0;
			padding: 0;
			position: relative;

			&.menu-item-home {
				visibility: hidden;
				width: 0;
				height: 0;
			}

			&.current-menu-item {
				a {
					color: #3C70DE;
				}
			}

			&:before {
				display: none;
			}

			&:after {
				content: '';
				width: 1px;
				height: 10px;
				background: #13111C;
				position: absolute;
				left: auto;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			&:last-child {

				&:after {
					display: none;
				}
			}

			> a {
				display: inline-block;
				font-weight: 300;
				font-size: 14px;
				line-height: 1.21;
				color: #13111C;
				mix-blend-mode: normal;
				opacity: 0.86;
				padding: 0 25px;
				transition: opacity 0.3s, color 0.3s;

				&:hover {
					opacity: 1;
					color: #3C70DE;
				}

			}
		}
	}

	&__link-icon {
		padding-left: 20px;
		position: relative;
		text-transform: none;

		&:before {
			content: '';
			width: 10px;
			height: 10px;
			position: absolute;
			left: 0;
			top: 3px;
		}


		> a {
			text-transform: none;
		}
	}

	&__navigation {
		width: 100%;
		margin: 0;
	}

	&__navigation-list {
		display: flex;
		list-style: none;
		position: relative;
		margin: 0;

		@include lg {
			justify-content: space-between;

		}

		.menu-item {

			&.current-menu-item {

				> a {
					color: #FF4600;

					&:after {
						opacity: 1;
					}
				}


			}

			&:before {
				display: none;
			}


			> a {
				font-size: 13px;
				line-height: 16px;
				text-transform: uppercase;
				color: #13111C;
				position: relative;
				padding: 8px 15px 8px;
				display: block;

				@include laptop {
					padding: 14px 10px;
				}

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					transition: $transition;
					top: calc(100% - 2px);
					left: 0;
					height: 4px;
					background: #FF4600;
					opacity: 0;


					@include mobile {
						height: 1px;
					}
				}


				@include hover {
					color: #FF4600;

					&:after {
						opacity: 1;
					}
				}
			}

			&.menu-item-has-children {


				@include hover {

					.sub-menu {
						max-height: 500px;
						padding: 45px;
					}

					> a {
						color: #FF4600;


						&:before {
							background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMjEyNDkgMC4zMTg3OThDMS4wNDA3NCAwLjQ5NTU0OSAwLjk1NTMxNiAwLjcyNDA5OSAwLjk1NTMxNiAwLjk1MjY0OUMwLjk1NTMxNiAxLjE4OTM4IDEuMDQ3MSAxLjQyNjExIDEuMjMwMjEgMS42MDQyMkw0LjIxMTgxIDQuNTA0MDNDNC41NTUzMSA0Ljg1MjA4IDUuMDEzMzIgNS4wNDM4MiA1LjUwMTMyIDUuMDQzODJDNS45ODkzMSA1LjA0MzgyIDYuNDQ3MzIgNC44NTIwOCA2Ljc3ODU2IDQuNTE2NzVMOS43NjgzMyAxLjYwNDIyQzEwLjEyNzcgMS4yNTM0NCAxMC4xMzU1IDAuNjc4MjA3IDkuNzg1MTUgMC4zMTkyNTNDOS40MzQ4MiAtMC4wNDA2MTA3IDguODU5NTkgLTAuMDQ3ODgwNyA4LjUwMDE4IDAuMzAyNDQxTDUuNDkyMjMgMy4yMTQ1MkwyLjQ5NzkxIDAuMzAxOTg3QzIuMTM4MDUgLTAuMDQ4Nzg5NCAxLjU2MjgxIC0wLjA0MTA2NTEgMS4yMTI0OSAwLjMxODc5OFoiIGZpbGw9IiNGRjQ2MDAiLz48L3N2Zz4=) center center no-repeat;
							//transform: rotate(-90deg);
							transform: rotate(-180deg);
						}

						&:after {
							opacity: 1;
						}

					}
				}

				> a {
					padding-right: 40px;

					@include laptop {
						padding-right: 30px;
					}

					&:before {
						content: '';
						position: absolute;
						width: 10px;
						height: 6px;
						left: auto;
						top: calc(50% - 3px);
						right: 22px;
						background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMjEyNDkgMC4zMTg3OThDMS4wNDA3NCAwLjQ5NTU0OSAwLjk1NTMxNiAwLjcyNDA5OSAwLjk1NTMxNiAwLjk1MjY0OUMwLjk1NTMxNiAxLjE4OTM4IDEuMDQ3MSAxLjQyNjExIDEuMjMwMjEgMS42MDQyMkw0LjIxMTgxIDQuNTA0MDNDNC41NTUzMSA0Ljg1MjA4IDUuMDEzMzIgNS4wNDM4MiA1LjUwMTMyIDUuMDQzODJDNS45ODkzMSA1LjA0MzgyIDYuNDQ3MzIgNC44NTIwOCA2Ljc3ODU2IDQuNTE2NzVMOS43NjgzMyAxLjYwNDIyQzEwLjEyNzcgMS4yNTM0NCAxMC4xMzU1IDAuNjc4MjA3IDkuNzg1MTUgMC4zMTkyNTNDOS40MzQ4MiAtMC4wNDA2MTA3IDguODU5NTkgLTAuMDQ3ODgwNyA4LjUwMDE4IDAuMzAyNDQxTDUuNDkyMjMgMy4yMTQ1MkwyLjQ5NzkxIDAuMzAxOTg3QzIuMTM4MDUgLTAuMDQ4Nzg5NCAxLjU2MjgxIC0wLjA0MTA2NTEgMS4yMTI0OSAwLjMxODc5OFoiIGZpbGw9IiMzQzcwREUiLz48L3N2Zz4=) center center no-repeat;
						transition: transform 0.3s;

						@include laptop {
							right: 5px;
						}
					}

					@include hover {

						&:before {
							background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMjEyNDkgMC4zMTg3OThDMS4wNDA3NCAwLjQ5NTU0OSAwLjk1NTMxNiAwLjcyNDA5OSAwLjk1NTMxNiAwLjk1MjY0OUMwLjk1NTMxNiAxLjE4OTM4IDEuMDQ3MSAxLjQyNjExIDEuMjMwMjEgMS42MDQyMkw0LjIxMTgxIDQuNTA0MDNDNC41NTUzMSA0Ljg1MjA4IDUuMDEzMzIgNS4wNDM4MiA1LjUwMTMyIDUuMDQzODJDNS45ODkzMSA1LjA0MzgyIDYuNDQ3MzIgNC44NTIwOCA2Ljc3ODU2IDQuNTE2NzVMOS43NjgzMyAxLjYwNDIyQzEwLjEyNzcgMS4yNTM0NCAxMC4xMzU1IDAuNjc4MjA3IDkuNzg1MTUgMC4zMTkyNTNDOS40MzQ4MiAtMC4wNDA2MTA3IDguODU5NTkgLTAuMDQ3ODgwNyA4LjUwMDE4IDAuMzAyNDQxTDUuNDkyMjMgMy4yMTQ1MkwyLjQ5NzkxIDAuMzAxOTg3QzIuMTM4MDUgLTAuMDQ4Nzg5NCAxLjU2MjgxIC0wLjA0MTA2NTEgMS4yMTI0OSAwLjMxODc5OFoiIGZpbGw9IiNGRjQ2MDAiLz48L3N2Zz4=) center center no-repeat;
							transform: rotate(-180deg);

						}
					}
				}


			}

		}

		.sub-menu {
			width: 100%;
			position: absolute;
			padding: 0 45px;
			left: 0;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: wrap;
			top: calc(100% + 1);
			margin: 0;
			overflow: hidden;
			max-height: 0;
			background: rgba(255, 255, 255, 0.99);
			mix-blend-mode: normal;
			box-shadow: 0 0 10px rgba(35, 62, 119, 0.179685);
			transition: max-height 0.4s, padding 0.4s;


			li {
				width: calc(33%);

				a {
					display: inline-block;
					text-transform: uppercase;
					color: #13111C;
					@include fz(13px, 16px);
					font-weight: 400;
					position: relative;
					padding: 10px 25px;
					border-radius: 16.5px;


					&:before {
						content: '';
						position: absolute;
						width: 1px;
						height: 10px;
						background: #FF4600;
						left: 20px;
						top: 13px;

					}

					&:after {
						display: none;

					}


					@include hover {
						background: #3A6DD8;
						color: #ffffff;

						&:before {
							background: #ffffff;
						}
					}
				}
			}
		}
	}
}

.single-vacancies {
	.b-header {
		background-color: #fff;
	}
}

.fast-order-mobile-btn {
	display: none;

	@include xs {
		display: block;
		margin-right: 20px;
		max-width: 80px;
		width: 100%;
		padding: 10px 8px 10px;
		font-size: 12px;
		line-height: 1.16;
	}

	&.b-button_orange_header {

		@include xs {
			max-width: 80px !important;
			padding: 10px 14px 10px !important;
			font-size: 12px !important;
			line-height: 1.16 !important;
			max-height: 38px !important;
		}
	}
}

.b-mobile-menu.active {
	.menu-item-has-children {
		position: relative;
		z-index: 1;


		> a {
			pointer-events: none;
		}

		&:after {
			position: absolute;
			content: '';
			display: block;
			right: 36px;
			top: 0;
			width: 6px;
			height: 10px;
			z-index: 1;
			background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDYgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAuMzE4OTIgOS43ODY1M0MwLjQ5NTY3MiA5Ljk1ODI5IDAuNzI0MjIxIDEwLjA0MzcgMC45NTI3NzEgMTAuMDQzN0MxLjE4OTUgMTAuMDQzNyAxLjQyNjIzIDkuOTUxOTIgMS42MDQzNCA5Ljc2ODgxTDQuNTA0MTUgNi43ODcyMkM0Ljg1MjIgNi40NDM3MSA1LjA0Mzk1IDUuOTg1NyA1LjA0Mzk1IDUuNDk3NzFDNS4wNDM5NSA1LjAwOTcxIDQuODUyMiA0LjU1MTcgNC41MTY4NyA0LjIyMDQ2TDEuNjA0MzQgMS4yMzA2OUMxLjI1MzU3IDAuODcxMjggMC42NzgzMjkgMC44NjM1NTYgMC4zMTkzNzUgMS4yMTM4OEMtMC4wNDA0ODg3IDEuNTY0MiAtMC4wNDc3NTg2IDIuMTM5NDQgMC4zMDI1NjMgMi40OTg4NEwzLjIxNDY0IDUuNTA2NzlMMC4zMDIxMDkgOC41MDExMUMtMC4wNDg2Njc0IDguODYwOTcgLTAuMDQwOTQzIDkuNDM2MjEgMC4zMTg5MiA5Ljc4NjUzWiIgZmlsbD0iIzNDNzBERSIvPjwvc3ZnPg==) center center no-repeat;
		}
	}

	.sub-menu {
		max-height: 0;
		overflow: hidden;
		position: static;
		transition: max-height .5s ease;
		margin-top: 0;
		margin-bottom: 0;

		.menu-item {
			position: relative;
			z-index: 1;
			padding-left: 30px;

			&:after {
				position: absolute;
				content: '';
				display: block;
				left: 22px;
				top: 11px;
				width: 5px;
				height: 5px;
				z-index: 1;
				background: #3C70DE;
			}

			a {
				text-transform: none;
				font-size: 15px;
				line-height: 1.2;
				color: rgba(60, 112, 222, 1);
				padding-left: 10px;
			}
		}
	}

	.menu-item {

		a {
			font-size: 13px;
			line-height: 1.15;
			text-transform: uppercase;
			color: #13101D;
			font-weight: 400;
			padding-left: 22px;
			margin: 0;

		}


		&.active {
			border-bottom: 2px solid  #FF4600;

			> a {
				text-decoration: none;
				margin-bottom: 3px;
				margin-top: 3px;

				&:after {
					background: transparent;
				}
			}

			&:after {
				transform: rotate(90deg);
			}

			.sub-menu {
				max-height: 100000px;
				margin-bottom: 3px;
				margin-top: 3px;

				li {
					&:last-of-type {
						margin-bottom: 30px;
					}

					&.active {
						border-bottom: none;
					}
				}
			}
		}
	}
}

.b-mobile-menu__content {
	#menu-glavnoe-menju-mob {

		.menu-item-has-children {
			position: relative;
			z-index: 1;


			> a {
				pointer-events: none;
			}

			&:after {
				position: absolute;
				content: '';
				display: block;
				right: 36px;
				top: 0;
				width: 6px;
				height: 10px;
				z-index: 1;
				background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDYgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAuMzE4OTIgOS43ODY1M0MwLjQ5NTY3MiA5Ljk1ODI5IDAuNzI0MjIxIDEwLjA0MzcgMC45NTI3NzEgMTAuMDQzN0MxLjE4OTUgMTAuMDQzNyAxLjQyNjIzIDkuOTUxOTIgMS42MDQzNCA5Ljc2ODgxTDQuNTA0MTUgNi43ODcyMkM0Ljg1MjIgNi40NDM3MSA1LjA0Mzk1IDUuOTg1NyA1LjA0Mzk1IDUuNDk3NzFDNS4wNDM5NSA1LjAwOTcxIDQuODUyMiA0LjU1MTcgNC41MTY4NyA0LjIyMDQ2TDEuNjA0MzQgMS4yMzA2OUMxLjI1MzU3IDAuODcxMjggMC42NzgzMjkgMC44NjM1NTYgMC4zMTkzNzUgMS4yMTM4OEMtMC4wNDA0ODg3IDEuNTY0MiAtMC4wNDc3NTg2IDIuMTM5NDQgMC4zMDI1NjMgMi40OTg4NEwzLjIxNDY0IDUuNTA2NzlMMC4zMDIxMDkgOC41MDExMUMtMC4wNDg2Njc0IDguODYwOTcgLTAuMDQwOTQzIDkuNDM2MjEgMC4zMTg5MiA5Ljc4NjUzWiIgZmlsbD0iIzNDNzBERSIvPjwvc3ZnPg==) center center no-repeat;
			}
		}

		.sub-menu {
			max-height: 0;
			overflow: hidden;
			position: static;
			transition: max-height .5s ease;
			margin-top: 0;
			margin-bottom: 0;

			.menu-item {
				position: relative;
				z-index: 1;
				padding-left: 30px;

				&:after {
					position: absolute;
					content: '';
					display: block;
					left: 22px;
					top: 11px;
					width: 5px;
					height: 5px;
					z-index: 1;
					background: #3C70DE;
				}

				a {
					text-transform: none;
					font-size: 15px;
					line-height: 1.2;
					color: rgba(60, 112, 222, 1);
					padding-left: 10px;
				}
			}
		}

		.menu-item {

			a {
				font-size: 13px;
				line-height: 1.15;
				text-transform: uppercase;
				color: #13101D;
				font-weight: 400;
				padding-left: 22px;
				margin: 0;

			}


			&.active {
				border-bottom: 2px solid  #FF4600;

				> a {
					text-decoration: none;
					margin-bottom: 3px;
					margin-top: 3px;

					&:after {
						background: transparent;
					}
				}

				&:after {
					transform: rotate(90deg);
				}

				.sub-menu {
					max-height: 100000px;
					margin-bottom: 3px;
					margin-top: 3px;

					li {
						&:last-of-type {
							margin-bottom: 30px;
						}

						&.active {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}