.b-field {
    //width: 100%;
	width: calc(50% - 15px);
    position: relative;
	margin-bottom: 25px;
}

.b-field_w50 {
	width: calc(50% - 40px);
	margin-bottom: 26px;

	.b-first-form__checkbox {
		margin-bottom: 0;
	}

	@include ss {
		width: 100%;
	}
}

.b-field__label {}

.b-field__input {
    width: 100%;
    border: none;
    @include fz(15px, 18px);
    padding: 3px 20px 8px;
    font-weight: 300;
	background-color: transparent;
	color: #1F1D25;
	opacity: 1;

	&::placeholder {
		color: #1F1D25;
	}
	//
    &:focus~.b-field__line {
        opacity: 1;
    }
    &:focus {
        &::placeholder {
	        color: #1F1D25;
        }
    }
}

textarea.b-field__input {
    min-height: 58px;
	height: 58px;

}

.b-first-form__input-textarea {
	margin-bottom: 12px;
}


.b-field__line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: $grad;
    top: 100%;
    left: 0;
    opacity: 0.45;
    transition: $transition;
}

.b-field__hint {}

.b-field__error {}