.b-time-filter {
    // background: $grad;
    //border: 1px solid $not-grad;
    width: 270px;
    //border-radius: 5px;
    height: 35px;
}

.b-time-filter__inner {
    position: relative;
    width: 100%;
    height: 100%;
	display: flex;
    //@include flex-row;
    //background-color: #fff;
    //@include flex-row;
    //@include flex-center;
    //border-radius: 4px;
    //&:after {
    //    content: '';
    //    position: absolute;
    //    width: 1px;
    //    height: 45%;
    //    top: 50%;
    //    left: 50%;
    //    transform: translate3d(-50%, -50%, 0);
    //    background: $grad;
    //}
}

.b-time-filter__select {
    width: 50%;
    //@include flex-row;
    //@include flex-center;
	display: flex;
    height: 100%;



    & select {
        @include fz(15px, 18px);
        cursor: pointer;
        padding: 5px 22px;
        border: none;
        height: 100%;
        width: 100%;
		background-color: #E9EFFC;
		appearance: none;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjM5OTUyIDAuNzM4NzIzQzAuMTkzNDE3IDAuOTUwODI0IDAuMDkwOTEgMS4yMjUwOCAwLjA5MDkxIDEuNDk5MzRDMC4wOTA5MSAxLjc4MzQyIDAuMjAxMDUgMi4wNjc0OSAwLjQyMDc4NSAyLjI4MTIzTDMuOTk4NyA1Ljc2MUM0LjQxMDkxIDYuMTc4NjYgNC45NjA1MiA2LjQwODc1IDUuNTQ2MTEgNi40MDg3NUM2LjEzMTcxIDYuNDA4NzUgNi42ODEzMiA2LjE3ODY2IDcuMDc4OCA1Ljc3NjI3TDEwLjY2NjUgMi4yODEyM0MxMS4wOTc4IDEuODYwMyAxMS4xMDcxIDEuMTcwMDEgMTAuNjg2NyAwLjczOTI2OEMxMC4yNjYzIDAuMzA3NDMyIDkuNTc2MDQgMC4yOTg3MDggOS4xNDQ3NSAwLjcxOTA5NEw1LjUzNTIxIDQuMjEzNTlMMS45NDIwMyAwLjcxODU0OEMxLjUxMDE5IDAuMjk3NjE3IDAuODE5OTA2IDAuMzA2ODg2IDAuMzk5NTIgMC43Mzg3MjNaIiBmaWxsPSIjM0M3MERFIi8+Cjwvc3ZnPgo=);
		background-repeat: no-repeat;
		background-position: 90% center;
		color: #1F1D25;
    }

	&:first-child select {
		border-radius: 100px 0 0 100px;
		margin-right: 1px;
	}

	&:last-child select {
		border-radius: 0 100px 100px 0;
	}
}