.b-hidden-buttons {
    @include flex-row;
    padding: 5px 5px;
    background-color: #fff;
    border-radius: 4px;
    mix-blend-mode: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    width: 320px;
    max-width: 100vw;
}

.b-hidden-buttons__item {
    width: 100%;
    margin: 5px;
}