.b-go-to-start {
    width: 44px;
    height: 44px;
    @include flex-row;
    @include flex-center;
    background: $grad;
    opacity: 0.6;
    color: $lightColor;
    box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.05);
    font-size: 36px;
    position: fixed;
    bottom: 28px;
    left: 10px;
	display: none;
	border-radius: 50%;

    @include xs {
        font-size: 28px;
    }
    cursor: pointer;
    z-index: 3;
    @include hover {
        opacity: 1;
    }

    &.active {
        display: flex;
    }
}