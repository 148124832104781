.b-cover-slide {}

.b-cover-slide__inner {}

.b-cover-slide__row {
    @include flex-row;
    @include row;
	margin-top: 0;
	margin-bottom: 0;
    align-items: center;

	@include md {
		flex-direction: column-reverse;

	}
}

.b-cover-slide__image {
    @include col(4);
    @include col-sm(4);

	@include md {
		display: none;
	}

	&_mob {
		display: none;
		width: 216px!important;
		height: 216px!important;
		margin: 16px auto;

		@include md {
			display: block;
		}

		@include mobile {
			margin-right: auto;
			margin-left: 0;
			max-width: 200px;
			margin-bottom: 16px;
		}
	}

	svg {
		max-width: 100%;
	}

    & img {
        display: block;
        max-width: 100%;
	    position: relative;
	    left: -200px;
	    top: 100px;

	    @include laptop {
		    left: -100px;
	    }

	    @include md {
		    left: 0;
		    top: 0;

	    }
    }

    @include sm {
        margin: auto;
	    width: 100%;
	    max-width: 320px;
    }
}

.b-cover-slide__content {
    //padding-left: 30px;
    @include col(8);
    @include col-sm(12);
	margin-top: 0;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;

	@include md {
		margin-bottom: 40px;
		text-align: center;

	}

}

.b-cover-slide__content_center {
    text-align: center;
}

.b-cover-slide__content_full {
    @include col(12);
}

.b-cover-slide__content_color-light {
    color: $lightColor;
}

.b-cover-slider__title {
    //font-size: 56px;
    //line-height: 1.2;
    // font-weight: 900;

    font-weight: 700;
    //text-transform: uppercase;
    margin-bottom: 10px;
    //@include lg {
    //    font-size: 34px;
    //    line-height: 52px;
    //}
    //@include md {
    //    font-size: 28px;
    //    line-height: 46px;
    //}
    //@include sm {
    //    font-size: 24px;
    //    line-height: 40px;
    //}
    //@include xs {
    //    margin-bottom: 15px;
    //}

    @include h_inh;

	@include mobile {
		@include fz(20px, 24px)
	}
}

.b-cover-slider__desc {
	max-width: 780px;
	font-weight: 300;
	font-size: 23px;
	line-height: 32px;
	color: #302E38;
    //margin-bottom: 30px;

    //@include lg {
    //    font-size: 20px;
    //    line-height: 28px;
    //}
    @include md {
        font-size: 16px;
        line-height: 24px;
	    //margin-bottom: 40px;
    }

	@include mobile {
		@include fz(16px, 20px)
	}

    //@include sm {
    //    font-size: 14px;
    //    line-height: 22px;
    //}
    //@include xs {
    //    margin-bottom: 25px;
    //}

}

.b-cover-slider__button {}