.b-seo {}

.b-seo_pb-big {
    padding-bottom: 200px;
}

.b-seo__inner {
    & .scroll-element_outer {
        width: 2px !important;
        border-radius: 5px !important;
    }
    & .scroll-element_track {
        background: $grad !important;
        opacity: 1 !important;
    }
    & .scroll-bar {
        background: $lightColor !important;
        opacity: 1 !important;
    }
}

.b-seo__content {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
    & h1 {
        @include fz(36px, 42px);
    }
    & h2 {
        @include fz(30px, 38px);
    }
    & h3 {
        @include fz(24px, 32px);
    }
    & h4 {
        @include fz(22px, 30px);
    }
    & h5 {
        @include fz(20px, 28px);
    }
    & h6 {
        @include fz(18px, 26px);
    }
    & p {
        @include fz(14px, 22px);
        margin-top: 10px;
        margin-bottom: 10px;
    }
}