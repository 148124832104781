h1 {
    @include fz(56px, 56px);
    margin-top: 0;
	font-weight: 700;
    margin-bottom: 15px;

	@include laptop {
		@include fz(45px, 55px);
	}

	@include lg {
		@include fz(40px, 50px);
	}

	@include md {
		@include fz(35px, 45px);
	}

	@include sm {
		@include fz(30px, 40px);
	}

	@include xs {
		@include fz(30px, 40px);
	}
}

h2 {
    @include fz(44px, 53px);
    margin-top: 0;
    margin-bottom: 15px;
	font-weight: 700;

	@include laptop {
		@include fz(45px, 55px);
	}

	@include lg {
		@include fz(40px, 50px);
	}

	@include md {
		@include fz(35px, 45px);
	}

	@include sm {
		@include fz(26px, 36px);
	}

	@include mobile480 {
		@include fz(24px, 34px);
	}

	//@include xs {
	//	@include fz(20px, 30px);
	//}
}

h3 {
    @include fz(34px, 41px);
    margin-top: 0;
    margin-bottom: 15px;

	@include sm {
		@include fz(20px, 30px);
	}

	@include mobile480 {
		font-size: 18px;
		line-height: 1.16;
	}
}

h4 {
    @include fz(22px, 26px);
    margin-top: 0;
    margin-bottom: 10px;
}

h5 {
    @include fz(18px, 21px);
    margin-top: 0;
    margin-bottom: 10px;
}

h6 {
    @include fz(16px, 20px);
    margin-top: 0;
    margin-bottom: 10px;
}

p {
    @include fz(16px, 22px);
    font-weight: 300;
	color: #1F1D25!important;
}

strong {
    font-weight: 700;
    //font-weight: 600;
}

ol,
ul,
li {
    list-style-type: none;
}

ol,
ul {
    margin-top: 3px;
    margin-bottom: 3px;
}

li {
    @include fz(14px, 24px);
    font-weight: 500;
}

ol {
    counter-reset: idNumListItem;
	margin-bottom: 14px;

    & li {
		@extend p;
		margin-bottom: 0;

		&:not(:last-child) {
			margin-bottom: 25px;
		}

        &:before {
            counter-increment: idNumListItem;
            content: counter(idNumListItem) ". ";
            margin-right: 8px;
            text-align: right;
            width: 15px;
            display: inline-block;
			font-family: 'SF Pro Display', sans-serif;
			font-style: normal;
			font-weight: bold;
			@include fz(16px, 19px);
			color: #3C70DE;

		}
    }
}

ul {

    //& li {
    //    &:before {
    //        content: "•";
    //        margin-right: 8px;
    //        text-align: right;
    //        width: 12px;
    //        display: inline-block;
    //    }
    //}
}

.blue-price {
	font-style: normal;
	font-weight: bold;
	display: flex;
	align-items: center;
	@include fz(22px, 26px);
	color: #3C70DE;
}

.discount {
	width: 140px;
	height: 110px;

	svg {
		width: 100%;
		height: 100%;
	}
}

textarea {
    resize: none;
}

a {
    text-decoration: none;
	font-family: 'SF Pro Display', sans-serif;
	font-style: normal;
	font-weight: normal;
	@include fz(16px , 19px);
	color: #3C70DE;

    @include hover {
        text-decoration: underline;
        text-decoration-color: $blueColor;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.b-title.big-title {
	@include fz(56px, 67px);
	letter-spacing: 1px;
	font-weight: 700;

	@include xs {
		@include fz(34px, 40px);
	}

	@include mobile {
		@include fz(28px, 38px);
	}
}