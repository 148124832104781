.b-logo {
  max-width: 150px;

  & img {
    display: block;
    width: 100%;
  }
  @include md {
    max-width: 120px;
  }
  @include xs {
    max-width: 100px;
  }
}
