.b-content-table {
    width: 100%;
	overflow-x: auto;
	margin-bottom: 20px;

    & table {
        min-width: 620px;
    }
//

}

.b-content-table table {
    width: 100%;
    position: relative;
    @include fz(14px, 21px);
    color: $darkColor;
    border-collapse: collapse;
    border: none !important;
}

.b-content-table table tr {
    text-align: left;
    &:not(:last-child){
        & th{
            border-bottom: 1px solid $not-grad;
        }
        & td{
            border-bottom: 1px solid rgba($not-grad, 0.6);
        }
    }
}

.b-content-table table th {
    text-align: left;
    text-transform: uppercase;
    // font-weight: 900;
    font-weight: 600;
    padding: 8px 12px;
}

.b-content-table table td {
    text-align: left;
	padding: 5px 10px 14px 0;
    &:last-child {
        padding-right: 0;
    }
}