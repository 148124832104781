.b-first-form {


	@include sm {
		width: 100%;
	}
}

.b-first-form__inner {
	margin: 0 15px;

}

.b-first-form__header {
    // background-image: url('../img/form/bg.png');
    background-color: $blueColor;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 40px 20px;
}

.b-first-form__header-text {
    @include fz(16px, 22px);
    font-weight: 500;
    color: $lightColor;
    margin-bottom: 20px;
}

.b-first-form__main {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	max-width: 760px;

	@include xs590 {
		padding: 0 18px;
	}

	@include mobile480 {
		flex-direction: column;
	}


	br {
		@include mobile480 {
			display: none;
		}
	}

	.b-field {
		@include mobile480 {
			width: 100%;
		}
	}

    //padding: 20px;
    //@include xs {
    //    padding: 10px;
    //}
}

.b-first-form__resume-submit {
	@include sm {
		display: inline-flex;
		width: 100%;
	}
}

.b-first-form__input {
	margin: 0;

	&_mb{
		margin-bottom: 20px;
	}
}

.b-first-form__input_checkbox {
	margin-left: auto;

	&.form-order-checkbox {
		width: 100%;
	}


	@include sm {
		margin-left: 0;
		width: 100%;
	}
}

.b-first-form__button {
    //width: 100%;
    text-align: center;
    justify-content: center;
}

.b-first-form__checkbox {
    @include flex-row;
	//margin-bottom: 28px;
	max-width: 630px;

}

.b-first-form__checkbox-label {}

.b-first-form__checkbox-input {
	background-color: transparent;
	outline: none;
	appearance: none;
	box-shadow: none;
	border-radius: 0;
	width: 14px;
	height: 14px;
	display: inline-block;
	position: relative;

	&:before {
		content: '';
		opacity: 1;
		position: absolute;
		background: #13111C;
		background-size: 6px auto;
		width: 14px;
		height: 14px;
		top: -1px;
		left: -1px;
	}

	&:checked {

		&:before {
			background: #13111C url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgNiA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01LjE1OTk5IDBMMi4wOTk5OSAzLjIxODc1TDAuODM5OTk1IDEuOTA2MjVMMCAyLjc4MTI0TDIuMDk5OTkgNUw2IDAuOTA2MjQxTDUuMTU5OTkgMFoiIGZpbGw9IndoaXRlIi8+PC9zdmc+) center center no-repeat;
		}
	}
}

.b-first-form__checkbox-text {
    cursor: pointer;
    @include fz(12px, 14px);
    font-weight: 300;
	color: rgba(31, 29, 37, 0.72);
	width: calc(100% - 25px);
    margin-left: auto;
    user-select: none;

	@include xs590 {
		padding-right: 15px;
	}
}

.b-first-form__checkbox-hint {}

.b-first-form__checkbox-error {}

.b-first-form__column {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@include sm {
		flex-wrap: wrap;
	}
}

.b-first-form__column-w100 {
	width: 100%;
}

.b-field.b-first-form__input_50 {
	width: calc(50% - 20px);
	margin-bottom: 40px;

	@include sm {
		width: 100%;
	}
}

.b-contacts.contacts .wpcf7 {

	width: 100%;
}

.wpcf7 .wpcf7-form {
	.wpcf7-response-output{
		margin-top: 10px;
		margin-bottom: 0;
		border: none;
	}

	&.failed{
		.wpcf7-response-output{
			color: #dc3232!important;
		}
	}

	&.invalid{
		.wpcf7-response-output{
			color: #ff4600!important;
		}
	}

	&.sent {
		.wpcf7-response-output{
			color: #46b450!important;
		}
	}
}

.b-first-form__input_file_wrapper {
	display: flex;
	align-items: center;


	> {
		flex-shrink: 0;
	}

	@include md {
		justify-content: center;

		span:last-child {
			margin-top: 10px;
			margin-bottom: 10px;
			flex-shrink: 0;
		}
	}
}

.b-first-form__input_file {
	border: 1px solid #3C70DE;
	border-radius: 16px;
	font-weight: 300;
	@include fz(13px, 16px);
	color: #3C70DE;
	position: relative;
	padding: 7px 14px 7px 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto!important;
	margin-right: 9px;
	cursor: pointer;
	flex-shrink: 0;

	@include tb {
		margin-left: 9px;
	}

	@include tb {
		width: 167px;
	}

	.resume_file {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		cursor: pointer;
		z-index: 2;
	}

	input[type=file]::-webkit-file-upload-button {
		cursor: pointer;
	}

	input[type="file"] {
		opacity: 0;
		padding: 0;
		position: relative;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&:before {
		content: '';
		display: block;
		width: 10px;
		height: 13px;
		position: absolute;
		left: 17px;
		top: 50%;
		transform: translateY(-50%);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAyLjUzM0w3LjQ2NyAwSDEuNTM4NDZWMS4xNTM4NUgwVjEzLjA3NjlIOC44NDYxNVYxMS41Mzg1SDEwVjIuNTMzWk03LjY5MjMxIDEuMzEzMTVMOC42ODY4NSAyLjMwNzY5SDcuNjkyMzFWMS4zMTMxNVpNMC43NjkyMzEgMTIuMzA3N0g4LjA3NjkyVjExLjUzODVIMS41Mzg0NlYxLjkyMzA4SDAuNzY5MjMxVjEyLjMwNzdaTTIuMzA3NjkgMTAuNzY5MlYwLjc2OTIzMUg2LjkyMzA4VjMuMDc2OTJIOS4yMzA3N1YxMC43NjkySDIuMzA3NjlaTTguNDYxNTQgNC4yMzA3N0g1VjVIOC40NjE1NFY0LjIzMDc3Wk0zLjA3NjkyIDUuNzY5MjNIOC40NjE1NFY2LjUzODQ2SDMuMDc2OTJWNS43NjkyM1pNOC40NjE1NCA4LjA3NjkyVjcuMzA3NjlINS4zODQ2MlY4LjA3NjkySDguNDYxNTRaTTMuMDc2OTIgNy4zMDc2OUg0LjYxNTM4VjguMDc2OTJIMy4wNzY5MlY3LjMwNzY5Wk04LjQ2MTU0IDguODQ2MTVIMy4wNzY5MlY5LjYxNTM5SDguNDYxNTRWOC44NDYxNVoiIGZpbGw9IiMzQzcwREUiLz4KPC9zdmc+Cg==);
	}
}