.b-mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 5;
    @include sm {
        display: block;
    }
    transform: translate3d(100%, 0, 0);
    transition: $transition;
    &.active {
        transform: translate3d(0, 0, 0);
    }
}

.b-mobile-menu__inner {
    padding: 15px;
    overflow: auto;
    max-height: calc(100% - 30px);
	margin-top: 60px;
}

.b-mobile-menu__content {
	padding-bottom: 40px;
}

.b-mobile-menu__content>ul {}

.b-mobile-menu__content>ul>li {
    &.active {
        &>a,
        &>span {
            padding-left: 15px;
            color: $orangeColor;
            text-decoration: underline;
        }
    }
    &:before {
        display: none;
    }
    &:not(:last-child) {
        margin-bottom: 25px;
    }
}

.b-mobile-menu__content>ul>li>a {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
    color: $blueColor;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: $orangeColor;
        @include mobile {
            height: 1px;
        }
    }
}

.b-mobile-menu__content>ul>li>ul {}

.b-mobile-menu__content>ul>li>ul>li {
    &:before {
        display: none;
    }
    padding-left: 15px;
    margin-bottom: 10px;
}

.b-mobile-menu__content>ul>li>ul>li>a {
    display: block;
    font-size: 14px;
    line-height: 28px !important;
    font-weight: 300;
    text-transform: uppercase;
    color: #000;
}