.b-contacts-shadow-block {

}

.b-contacts-shadow-block__inner {
    position: relative;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 60px;
}

.b-contacts-shadow-block__title {
    position: relative;
    @include fz(34px, 41px);
	font-weight: bold;
	font-size: 34px;
	line-height: 41px;
    color: $darkColor;
    margin-bottom: 15px;

    @include h_inh;
}

.b-contacts-shadow-block__title_center {
    text-align: center;
}

.b-contacts-shadow-block__title_non-underline {
    &:after {
        display: none !important;
    }
}

.b-contacts-shadow-block__desc {
    position: relative;
    display: flex;
	width: 100%;
	max-width: 555px;
	flex-direction: column;
    min-height: 88px;
}

.b-contacts-shadow-block__desc-text {
    position: relative;
	min-height: 66px;
	margin-bottom: 18px;
	font-weight: 300;
	@include fz(16px, 22px);
    & p {
        @include inh;
        padding: 0;
        margin: 0;
    }
    @include sm {
        width: 100%;
        margin-bottom: 10px;
    }
}

.b-contacts-shadow-block__desc-info {
	display: flex;
	justify-content: space-between;

	@include xs {
		flex-direction: column;
		justify-content: center;
	}
}

.b-contacts-shadow-block__desc-info-email ,
.b-contacts-shadow-block__desc-info-phone {
    position: relative;
	width: 100%;
    max-width: 260px;
	padding-left: 25px;

	@include xs {
		max-width: 220px;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 5px;
		left: 0;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		width: 14px;
		height: 14px;
	}

	a{
		color: #000;
		font-weight: 300;
		@include fz(16px, 22px);
	}

    & p {
        @include inh;
        padding: 0;
        margin: 0;
        & a {
            @include inh;
        }
    }
    @include sm {
        width: 100%;
        // text-align: right;
    }
}

.b-contacts-shadow-block__desc-info-email {
	@include xs {
		margin-bottom: 20px;
	}

	&:before {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAxNjQgNi4yMDI1NkwxMy44MzE0IDIuNDc3MjNDMTMuMzg1NSAxLjAxMDE1IDEyLjAzNSAwLjAwNTM5NTQxIDEwLjUwMTYgMEgzLjUwMTY0QzEuOTY4MyAwLjAwNTM5NTQxIDAuNjE3NzY0IDEuMDEwMTUgMC4xNzE4NzUgMi40NzcyM0w3LjAwMTY0IDYuMjAyNTZaIiBmaWxsPSIjM0M3MERFIi8+CjxwYXRoIGQ9Ik03LjMzNTMgNy42MTQ1NkM3LjEyNjI5IDcuNzI4NDkgNi44NzM3MSA3LjcyODQ5IDYuNjY0NyA3LjYxNDU2TDAgMy45NzkyNVY5LjEwMDAzQzAuMDAyMjc1OCAxMS4wMzIxIDEuNTY3OTUgMTIuNTk3OCAzLjUgMTIuNkgxMC41QzEyLjQzMjEgMTIuNTk3OCAxMy45OTc3IDExLjAzMjEgMTQgOS4xMDAwM1YzLjk3OTI1TDcuMzM1MyA3LjYxNDU2WiIgZmlsbD0iIzNDNzBERSIvPgo8L3N2Zz4K);
	}
}

.b-contacts-shadow-block__desc-info-phone {
	&:before {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjgxNDcgMTAuNzkwMUwxMC42NzQzIDguNTM3OThDMTAuNTI4IDguNDMzMDUgMTAuMzMyOCA4LjQyNjg0IDEwLjE4MDEgOC41MjIyNkw4LjQxMDc5IDkuNjI3ODhDOC4zMTU2MyA5LjY0NjEyIDcuOTAxOTggOS41Njg3IDcuMTAyMTggOC45ODk3MUM2LjQyOTcyIDguNTAyOTYgNS43MDkwOSA3LjgyMTQxIDUuMTc0NTIgNy4xNjY2M0M0LjM4MjQyIDYuMTk2NDEgNC4zMjMzOCA1LjcwNzIzIDQuMzY2ODUgNS41OTc1Nkw1LjQ3Nzc0IDMuODE5ODRDNS41NzMxNiAzLjY2NzE1IDUuNTY2OTUgMy40NzE5OCA1LjQ2MjAyIDMuMzI1NjVMMy4yMDk4IDAuMTg1Mjg2QzMuMTMzNzkgMC4wNzkzMTkxIDMuMDE1MDcgMC4wMTIxMDkxIDIuODg1MTIgMC4wMDE0Nzk4M0MyLjc1NTI5IC0wLjAwOTE0OTQyIDIuNjI3MDggMC4wMzc4NjggMi41MzQ4NiAwLjEzMDA2N0wwLjkxNDQ0MiAxLjc1MDM2Qy0wLjAwMzQ2Mzk4IDIuNjY4MjEgLTAuMjU4OTgxIDQuMDY1ODIgMC4yNzg2MTEgNS4yMjgyQzAuOTk3NDYyIDYuNzgyNDEgMS45MzA3IDguMjE4NiAzLjA1MjQzIDkuNDk2OUM0LjY1NTk3IDExLjMyNDIgNi41OTQyOCAxMi43NTkzIDguODEzNjEgMTMuNzYyMkM5Ljk0OTg0IDE0LjI3NTggMTEuMzI0NSAxNC4wMTA1IDEyLjIzMzkgMTMuMTAxMUwxMy44Njk5IDExLjQ2NTFDMTMuOTYyMSAxMS4zNzI5IDE0LjAwOTIgMTEuMjQ0OCAxMy45OTg1IDExLjExNDlDMTMuOTg3OSAxMC45ODQ5IDEzLjkyMDcgMTAuODY2MSAxMy44MTQ3IDEwLjc5MDFaIiBmaWxsPSIjM0M3MERFIi8+Cjwvc3ZnPgo=);
	}
}

.b-contacts-shadow-block__row {
    padding-top: 15px;
    @include flex-row;
    justify-content: space-between;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 25px;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $grad;
        opacity: 0.6;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(91.95deg, #3478dd 0%, #7939e7 100%);
        opacity: 0.6;
    }
}

.b-contacts-shadow-block__row_mt {
    margin-top: 66px;
}

.b-contacts-shadow-block__text {
    display: inline-flex;
    align-items: baseline;
    &:first-child {
        margin-right: 15px;
    }
    & strong {
        margin-right: 10px;
        @include fz(18px, 21px);
        font-weight: 700;
    }
    @include fz(14px, 21px);
    font-weight: 500;
    @include sm {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    @include xs {
        flex-wrap: wrap;
        & strong {
            display: block;
            width: 100%;
        }
    }
}

.b-contacts-shadow-block__form {
    margin-left: auto;
    margin-right: auto;
    max-width: 560px;
}

.b-contacts-block-inner {
	border-left: 4px solid #FF4600;
	background-color: #F9FBFE;
	padding: 50px 15px 60px 57px;
	width: calc(50% - 30px);
	margin-bottom: 34px;

	@include md {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}

	@include sm {
		width: 100%;
	}

	@include xs {
		padding: 20px;
	}
}

.b-contacts-subtitle {
	font-style: normal;
	font-weight: 300;
	@include fz(20px, 28px);
	color: #1F1D25;
}

/*Contact form 7*/

.display-none {
	display: none;
}