.b-filter-buttons {
    @include flex-row;
    @include flex-center;
}

.b-filter-button {
    @include fz(14px, 21px);
    font-weight: 500;
    color: #000;
    cursor: pointer;
    padding: 12px 14px;
    border-radius: 3px;
    background: transparent;
    @include mobile {
        width: 50%;
        text-align: center;
    }
    &.active {
        color: $lightColor;
        background: $grad;
    }
}