//colors
$accentColor: #ed11ee;
$darkColor: #1e1e1e;
$lightColor: #ffffff;
$greenColor: #065746;
$redColor: #d96762;
$orangeColor: rgb(255, 70, 0);
$blueColor: #3c70de;
// $grad: linear-gradient(91.95deg, #3478dd 0%, #7939e7 100%);
$grad: #3c70de;
$not-grad: #3478DD;
//fonts
// $firstFont: "M PLUS 1p",
// sans-serif;
//$firstFont: "Montserrat",
$firstFont: "SF Pro Display",
sans-serif;
//
$transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
//
$radius: 15px;