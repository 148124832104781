.b-phone-data {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transform: translate3d(0, 100%, 0);
    transition: $transition;
    &.active {
        transform: translate3d(0, 0, 0);
        & .b-phone-data__trigger {
            & i {
                &:nth-child(1) {
                    opacity: 0;
                }
                &:nth-child(2) {
                    opacity: 1;
                }
            }
            z-index: 1;
        }
    }
    display: none;
    @include sm {
        display: block;
    }
    z-index: 10;
}

.b-phone-data__inner {
    background: #FFFFFF;
}


.b-phone-data__trigger {
	position: absolute;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	top: -114px;
	right: 16px;
	@include flex-row;
	@include flex-center;
	background: #FF4600;
	box-shadow: 0px 0px 20px rgba(255, 70, 0, 0.360989);
	& i {
		@include grad($grad, $not-grad);
		font-size: 30px;
		transition: $transition;
		&:nth-child(1) {
			animation: phone 3s linear infinite alternate-reverse;
			opacity: 1;
		}
		&:nth-child(2) {
			position: absolute;
			opacity: 0;
		}
	}
	z-index: 1;
}

@keyframes phone {
    0%,
    40%,
    60%,
    100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(10deg);
    }
}

.b-phone-data__content {
    z-index: 2;
    position: relative;
    background: #FFFFFF;
    padding: 30px;
    @include flex-colm;
    @include flex-center;
}

.b-phone-data__link-icon {
    margin-bottom: 15px;
    & a {
        font-size: 12px !important;
    }
    & i {
        font-size: 20px !important;
    }
}