.b-2-col-block {

	&_w50 {
		width: 50%;

		@include sm {
			width: 100%;
		}
	}
}

.b-2-col-block__inner {
	height: 100%;
}

.b-2-col-block__row {
    @include flex-row;
    // @include row;

	&_bg {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		padding: 70px 60px 84px 60px;
		height: 100%;

		@include md {
			padding: 64px 20px 76px 20px;
		}
	}

}

.b-2-col-block__col {
    @include col(6);
    @include col-lg(12);
    display: flex;
}

.b-2-col-block__col-inner {
    margin: auto;
    width: 100%;
    @include lg {
        max-width: 660px;
    }
}

.b-2-col-block__image {
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: 60.55%;
    position: relative;
    // @include lg {
    //     padding-bottom: 20%;
    // }
    // @include xs {
    //     padding-bottom: 33%;
    // }
}

.b-2-col-block__image-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.b-2-col-block__title {
    margin-bottom: 8px;
    @include fz(24px, 36px);
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    color: #000;
    @include h_inh;

	&_white {
		color: #fff;
		text-transform: none;
		font-weight: bold;
		@include fz(34px, 41px);

		br {
			@include  mobile {
				display: none;
			}
		}

	}
}

.b-2-col-block__desc {
    @include fz(18px, 32px);
    font-weight: 500;
    color: $darkColor;
    margin-bottom: 26px;
    & p {
        margin: 0;
        padding: 0;
        @include inh;
    }

	&_white {
		color: #fff!important;

		p {
			font-weight: 300;
			@include fz(18px, 24px);
			color: #fff!important;
		}
	}
}

.b-2-col-block__button1 {
    padding: 14px 70px !important;
    @include sm {
        padding: 12px 50px !important;
    }
}

.flex-center{
	display: flex;
	justify-content: center;

	@include sm {
		justify-content: flex-start;
	}
}

.b-1-col-block__col-inner_w{
	max-width: 560px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.b-1-col-block__col-inner_0 {
	margin-left: -96px;

	@include xl1700 {
		margin-left: 0;
	}
}

.b-1-col-block__col-inner_1 {
	margin-left: -217px;

	@include xl1700 {
		margin-left: 0;
	}
}

.b-first-slider__store-slider {
	.b-first-slider__list{
		display: flex;
		flex-wrap: wrap;
	}

	.b-first-slider__arrows{
		display: none;
	}
}

.stock-block{
	padding-top: 111px;

	@include sm {
		padding-top: 20px;
	}
}

div[data-first-slider-list] {
	visibility: hidden;

	&.slick-initialized{
		visibility: visible;
	}
}

.b-2-col-block__desc_height {
	min-height: 72px;
}