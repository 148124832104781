.b-link-icon {
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
	position: relative;

	&:before {
		content: '';
		width: 10px;
		height: 10px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

    & i {
        white-space: nowrap;
        @include fz(24px, 24px);
        margin-right: 10px;
        // @include grad($grad, $not-grad);
        text-decoration: none !important;
        color: $orangeColor;
    }
    & a {
        white-space: nowrap;
	    color: #13111C;
	    font-weight: 300;
        //color: $blueColor;
        text-decoration: none;
        // text-decoration-color: $orangeColor;
        @include fz(15px, 18px);

    }
    @include hover {
        & a {
            opacity: 0.7;
        }
    }
}

