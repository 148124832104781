.b-advantage {
    position: relative;

	@include ss {
		padding-bottom: 130px;
	}
}

.b-advantage__inner {
    @include wrapper;
    position: relative;
}

.b-advantage_bg-lines {
    position: absolute;
    top: 50%;
	left: auto;
	right: -32%;
	transform: translate3d(0, -50%, 0);

	@include md {
		min-height: 100%;
		top: 5%;
		transform: none;
	}

	@include sm {
		top: 0;
		display: block;
		max-width: 200%;
		width: 200%;
		height: 100%;
		right: -50%;
	}
}

.b-advantage_bg-logo {
	position: absolute;
	top: 12px;
	z-index: -1;
	left: auto;
	right: 0;

	@include laptop {
		max-width: 50%;
		right: 0;
	}

	@include sm {
		max-width: 90%;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);
	}

	@include ss {
		max-width: 90%;
		top: auto;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.b-advantage__content {
    max-width: 100%;
    margin-left: auto;
	margin-right: auto;
}

.b-advantage__title {
    margin-bottom: 0;
	text-transform: none;
	@include fz(56px, 67px);
	letter-spacing: 1px;

    @include mobile {
        @include fz(36px, 40px);
    }
    //@include xs {
    //    margin-bottom: 30px;
    //}
}

.b-advantage__list {
    @include flex-row;
    @include row;
	margin-top: 0;
	margin-bottom: 0;
	justify-content: space-between;
	padding-left: 10px;
}

.b-advantage__list-item {
    @include col(4);
    @include col-md(12);
    @include col-sm(12);
    @include col-xs(12);
	display: flex;
	margin: 0 0 70px;

	@include md {
		margin-bottom: 40px;
	}
}

.about-us {
	.b-text-block__content_p {
		padding-left: 20px;

		@include ss {
			padding-left: 15px;
		}
	}

	.b-section__title-wrapper {

		@include xs-mobile {
			margin-left: -5px;
		}
	}

	.b-section__inner-small {
		padding: 0;
	}

	.advantage {
		overflow: visible;
	}

	.b-contacts__contacts_without_form {
		z-index: 3;
	}

	.b-text-block__content {
		position: relative;
		z-index: 3;
	}

	.b-section_padding-bottom-norm {
		padding-bottom: 19px;
	}

	.b-footer__row_bg-dark {
		position: relative;
		z-index: 2;
	}

	.b-advantage_bg-lines {
		z-index: -1;
	}

	.b-advantage__list-item {
		&:last-child {
			width: 65%;

			@include md {
				width: 100%;
				 br {
					 display: none;
				 }
			}
		}
	}
}

.b-advantage-item__image {
	margin-left: 66px;

	@include laptop {
		max-width: 170px;
	}
}

// 
.b-advantage-item {}

.b-advantage-item__header {
    margin-bottom: 0;
	width: 38px;
	flex-shrink: 0;
	margin-right: 20px;
}

.b-advantage-item__icon {
    & img {
        max-width: 50px;
        max-height: 50px;
        display: block;
    }
}

.b-advantage-item__main {
	//padding-left: 22px;
	width: calc(100% - 58px);
}

.b-advantage-item__title {
    @include fz(18px, 22px);
    // font-weight: 900;
    font-weight: 300;
    margin-bottom: 0;
    color: #1F1D25;
    @include h_inh;

	@include laptop {
		@include fz(16px, 20px);
	}

	@include sm {
		@include fz(14px, 18px);
	}


	p {
		@include inh;

		&:first-child {
			font-weight: 900;
		}

		em {
			color: #3C70DE;
			font-style: normal;
		}

		strong {
			font-size: 50px;
			line-height: 1;
			font-weight: 900;

			@include laptop {
				font-size: 35px;
			}

			@include sm {
				font-size: 28px;
			}
		}

		span {
			font-weight: 900;
		}
	}
}

.b-advantage-item__desc {
    @include fz(16px, 24px);
    color: $darkColor;
    & p {
        padding: 0;
        margin: 0;
        @include inh;
    }
}

.b-advantage-item__footer {}

.advantage {
	padding: 85px 0 110px;
	overflow: hidden;

	@include md {
		padding-bottom: 20px;
	}
	&__title-wrapper {
		padding-left: 25px;
		margin-bottom: 75px;

		@include md {
			margin-bottom: 45px;
		}

	}
}