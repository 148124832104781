.b-multilang {}

.b-multilang__inner {
    padding: 10px;
	height: 36px;
	width: 73px;
	display: flex;
	justify-content: center;
	align-items: center;

	@include sm {
		padding: 2px;
		height: 100%;
		width: 100%;
	}

    @include hover {
        & .b-multilang__title {
            & i {
                transform: rotate(180deg);
            }
        }
        & .b-multilang__list {
            opacity: 1;
            pointer-events: fill;
            transform: translate3d(0, 0, 0);
        }
    }
    position: relative;
}

.b-multilang__title {
    cursor: pointer;
    span {
        text-transform: uppercase;
        @include fz(14px, 21px);
        font-weight: 400;
        // color: #000;
        color: $blueColor;
    }
    & i {
		position: relative;
		top: -1px;
		left: 4px;
		font-weight: 700;
        color: $blueColor;
        transition: $transition;

		@include sm {
			top: 0;
		}
    }
    @include flex-row;
    @include flex-center;

    flex-wrap: nowrap !important;
    z-index: 2;
    background-color: transparent;
    position: relative;
}

.b-multilang__list {
    transition: $transition;
    position: absolute;
	border: 1px solid #3c70de;
	border-top: 1px solid transparent;
	border-radius: 0 0 22.5px 22.5px;
    width: calc(100% + 2px);
    top: 100%;
    left: -1px;
    @include flex-colm;
    @include flex-center;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 5px, 0);
    background-color: #fff;
    padding: 0 14px 8px 14px;
    mix-blend-mode: normal;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    z-index: 1;

	@include sm {
		padding: 0 9px 8px 9px;
		border: transparent;
	}


	&:before {
		content: '';
		position: absolute;
		width: 100%;
		top: -16px;
		height: 16px;
		background-color: #fff;
		z-index: 2;
		border-left: 1px solid #3c70de;
		border-right: 1px solid #3c70de;

		@include sm {
			//border-left: 1px solid transparent;
			//border-right: 1px solid transparent;
			display: none;
		}
	}

}

.b-multilang__list-item {
    display: block;
    text-transform: uppercase;
    @include fz(14px, 21px);
    font-weight: 300;
    color: #000;
    width: 100%;
    text-align: left;
    padding: 4px;
    margin-left: -4px;
}