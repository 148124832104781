.b-select {}

.b-select__label {}

.b-select__input {
    padding: 2px 20px 7px 20px;
    width: 100%;
    background-color:transparent;
	box-shadow: none;
	appearance: none;
	outline: none;
    border: none;
    border-radius: 0;
    @include fz(15px, 18px);
    font-weight: 300;
	color: #1F1D25;
}

.b-first-form__input-select {
	position: relative;

	select {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow:hidden;
		padding: 2px 40px 7px 20px;

		@include mobile {
			max-width: 300px;
			width: 100%;
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: auto;
		right: 0;
		width: 25px;
		height: 100%;
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAuODk5NTIgMC43Mzg2NjFDMC42OTM0MTcgMC45NTA3NjMgMC41OTA5MSAxLjIyNTAyIDAuNTkwOTEgMS40OTkyOEMwLjU5MDkxIDEuNzgzMzYgMC43MDEwNSAyLjA2NzQzIDAuOTIwNzg1IDIuMjgxMTdMNC40OTg3IDUuNzYwOTRDNC45MTA5MSA2LjE3ODYgNS40NjA1MiA2LjQwODY5IDYuMDQ2MTEgNi40MDg2OUM2LjYzMTcxIDYuNDA4NjkgNy4xODEzMiA2LjE3ODYgNy41Nzg4IDUuNzc2MkwxMS4xNjY1IDIuMjgxMTdDMTEuNTk3OCAxLjg2MDI0IDExLjYwNzEgMS4xNjk5NSAxMS4xODY3IDAuNzM5MjA3QzEwLjc2NjMgMC4zMDczNzEgMTAuMDc2IDAuMjk4NjQ3IDkuNjQ0NzUgMC43MTkwMzNMNi4wMzUyMSA0LjIxMzUyTDIuNDQyMDMgMC43MTg0ODdDMi4wMTAxOSAwLjI5NzU1NiAxLjMxOTkxIDAuMzA2ODI1IDAuODk5NTIgMC43Mzg2NjFaIiBmaWxsPSIjM0M3MERFIi8+PC9zdmc+) left center no-repeat;
		pointer-events: none;


	}
}

.b-select__hint {}

.b-select__error {}

input[type="number"]{
	appearance: none;
}

.b-first-form__input-order-form {
	margin-bottom: 20px;
}