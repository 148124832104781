.b-sidebar {
    width: 100%;
}

.b-sidebar__inner1 {
    padding-right: 3px;
    position: sticky;
    max-height: 100vh;
    top: 0;
    padding-bottom: 15px;
    background-color: #fff;
    z-index: 2;
    @include xs {
        padding: 15px;
    }
}

.b-sidebar__content {
    overflow: auto;
    padding-bottom: 15px;
}

.b-sidebar__header {
    position: relative;
	margin-bottom: 20px;
	display: none;

    &:after {
        //content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: rgba($orangeColor, 0.5);
    }
}

.b-sidebar__header-row {
    @include flex-row;
}

.b-sidebar__title {
    @include flex-row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 30px;
}

.b-sidebar__title-icon {
    @include fz(24px, 24px);
    @include grad($grad, $not-grad);
    margin-right: 15px;
}

.b-sidebar__icon-close {
    @include fz(24px, 24px);
    @include grad($grad, $not-grad);
    margin-left: auto;
    display: none;
    position: absolute;
	top: 45px;
    right: 15px;
    z-index: 2;

    @include sm {
        display: block;
    }

	@include ss {
		top: 54px;
	}
}

.b-sidebar__title-text {
    @include fz(24px, 36px);
    font-weight: 500;
    color: $darkColor;
}

.b-sidebar__main {
	@include sm {
		padding: 70px 40px 140px;
	}

	@include ss {
		padding: 60px 40px 140px;
	}

	@include mobile480 {
		padding: 40px 40px 140px;
	}

	@include xs-mobile {
		padding: 30px 40px 140px;
	}

	@include mobile-320 {
		padding: 50px 40px 140px;
	}

}

.b-sidebar__main-row {
    @include flex-row;
}

.b-sidebar__group {
	background-color: #fff;
    width: 100%;
    position: relative;
    //padding-top: 10px;
    //padding-bottom: 20px;
	margin-bottom: 24px;

    &:not(:last-child) {
        &:after {
            position: absolute;
            //content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            // background: $grad;
            background-color: $orangeColor;
        }
    }
}

.b-sidebar__subtitle {
    @include fz(18px, 24px);
	color: #fff;
    font-weight: 500;
    margin-bottom: 24px;
	background-color: rgba(60, 112, 222, 0.7);
	padding: 11px 14px;
}

.b-sidebar__checkbox-list {
	margin: 30px 18px 30px 10px;
	max-height: 252px;
	overflow: auto;

	@include ss {
		margin: 10px;
		padding-right: 15px;
	}
}

.b-sidebar__checkbox {
	border-bottom: 1px dashed rgba(41, 39, 47, 0.12);
	padding-bottom: 11px;
	padding-top: 11px;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.b-sidebar__footer {
	padding-bottom: 30px;
}

.b-sidebar__footer-row {
    @include flex-row;
}

.b-sidebar__button {
    cursor: pointer;
}

.b-sidebar__list {
    & ul {
        & li {
            cursor: pointer;
            text-transform: uppercase;
            color: #666;
            &:before {
                @include grad($grad, $not-grad);
                opacity: 0;
                transition: $transition;
            }
            &.active {
                color: $blueColor !important;
                pointer-events: none;
                & a {
                    font-weight: 700 !important;
                }
                &:before {
                    opacity: 1;
                }
            }
            & span {
                font-weight: 500;
                text-decoration: none;
                @include hover {
                    text-decoration: underline;
                    text-decoration-color: $blueColor;
                }
                color: inherit;
            }
            & a {
                font-weight: 500;
                text-decoration: none;
                @include hover {
                    text-decoration: underline;
                    text-decoration-color: $blueColor;
                }
                color: inherit;
            }
        }
    }
}

.b-sidebar__trigger {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    display: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    z-index: 1;
    & i {
        @include grad($grad, $not-grad);
        @include fz(28px, 28px);
    }
    // @include xs {
    //     @include flex-row;
    //     @include flex-center;
    // }
}
