.b-section-title-and-buttons {}

.b-section-title-and-buttons__inner {
    display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.b-section-title-and-buttons__title {
	font-family: 'SF Pro Display', sans-serif;
	font-style: normal;
	font-weight: bold;
	@include fz(34px, 41px);
	color: #13111C;
	margin-bottom: 26px;
	
	@include ss {
		margin-left: auto;
		margin-right: auto;
	}
	
    @include xs {
        margin-bottom: 20px;
    }
    @include h_inh;
}

.b-section-title-and-buttons__buttons {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-right: 10px;
	}
    @include ss {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @include flex-row;
        @include flex-center;
        margin-top: 15px;
        &>.b-button {
            flex-grow: 1;
            margin: 5px;
        }
    }
}

.b-section-title-and-buttons_in-form {
	padding-left: 22px;

	@include lg {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		@include flex-row;
		justify-content: flex-start;
		margin-top: 15px;
		&>.b-button {
			flex-grow: 1;
			margin: 5px;
		}
	}
}

.b-section-title-and-buttons__button {
    &:not(:last-child) {
        margin-right: 15px;
    }

}

.single-vacancies {
	.b-section-title-and-buttons__buttons {
		margin-bottom: 60px;
	}
}