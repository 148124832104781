// .b-preloader {
//     position: fixed;
//     z-index: 1000;
//     background-color: #fff;
//     width: 100%;
//     height: 100%;
//     @include flex-colm;
//     @include flex-center;
// }
// .b-paceholder__loader {
//     @include flex-row;
// }
// .b-paceholder__block {
//     margin: 10px;
//     width: 25px;
//     height: 25px;
//     animation: preloaderAnim 1.8s ease-in-out infinite;
//     &:nth-child(1) {
//         background: linear-gradient(to top, #3478dd 0%, #7939e7 100%);
//         animation-delay: 0s;
//     }
//     &:nth-child(2) {
//         background: linear-gradient(to left, #3478dd 0%, #7939e7 100%);
//         animation-delay: .2s;
//     }
//     &:nth-child(3) {
//         background: linear-gradient(to bottom, #3478dd 0%, #7939e7 100%);
//         animation-delay: 0.4s;
//     }
//     &:nth-child(4) {
//         background: linear-gradient(to right, #3478dd 0%, #7939e7 100%);
//         animation-delay: 0.6s;
//     }
//     &:nth-child(5) {
//         background: linear-gradient(to top, #3478dd 0%, #7939e7 100%);
//         animation-delay: 0.8s;
//     }
// }
// @keyframes preloaderAnim {
//     0% {
//         transform: translateY(0px) rotate(0deg);
//     }
//     25% {
//         transform: translateY(-45px) rotate(45deg);
//     }
//     50% {
//         transform: translateY(0px) rotate(90deg);
//     }
//     75% {
//         transform: translateY(-45px) rotate(45deg);
//     }
//     100% {
//         transform: translateY(0) rotate(90deg);
//     }
// }
// 
// 
// 
// 
// 
// 
// 
// .b-preloader {
//     position: fixed;
//     z-index: 1000;
//     background-color: $lightColor;
//     width: 100%;
//     height: 100%;
// }
// .b-preloader__loader {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
// }
// .b-preloader__block {
//     display: none;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate3d(-50%, -50%, 0);
//     color: $darkColor;
//     font-size: 90px;
//     font-weight: 900;
//     text-transform: uppercase;
//     @include md {
//         font-size: 72px;
//     }
//     @include sm {
//         font-size: 52px;
//     }
//     @include xs {
//         font-size: 38px;
//     }
//     @include mobile {
//         font-size: 28px;
//     }
//     & img,
//     & svg {
//         max-width: 100%;
//         width: 50vw;
//         display: block;
//     }
// }
.b-preloader {
    @include flex-row;
    @include flex-center;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $lightColor;
}

.b-preloader__inner {
    position: relative;
    width: 280px;
    height: 280px;
}

.b-preloader__inner svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // 
    stroke: $not-grad;
    fill: transparent;
    stroke-width: 2px;
    stroke-dashoffset: -3.14 * 2 * 141px;
    stroke-dasharray: 3.14 * 2 * 141px;
    animation: preloader_animate 4s cubic-bezier(0.42, 0, 0.29, 1) infinite;
    transform-origin: 50%;
    @include xs {
        stroke-width: 1px;
    }
}

@keyframes preloader_animate {
    0% {
        stroke-dashoffset: 3.14 * 2 * 141px;
        stroke: #7939e7;
    }
    50% {
        stroke-dashoffset: 0;
        stroke: #3478dd;
    }
    100% {
        stroke-dashoffset: -3.14 * 2 * 141px;
        stroke: #7939e7;
    }
}