.b-input-range {
    @include flex-row;
    display: inline-flex;
    @include flex-center;
}

.b-input-range__trigger {
    transition: $transition;
    user-select: none;
    width: 35px;
    height: 35px;
    @include flex-row;
    @include flex-center;
    cursor: pointer;
    background: $grad;
    color: $lightColor;
    font-size: 14px;
    line-height: 14px;
    &.disabled {
        cursor: default;
        opacity: 0.6;
    }
}

.b-input-range__output {
    @include flex-row;
    @include flex-center;
    height: 35px;
    padding: 7px 14px;
    font-size: 14px;
    line-height: 14px;
    min-width: 70px;
    background-color: #e0e0e0;
}

.b-input-range__input {
    display: none;
}