.b-footer-soc-list{
  @include  flex-row();
  align-items: center;
	flex-wrap: nowrap;
}

.b-footer-soc-list__item{
  //color: $lightColor;
  @include  fz(18px, 18px);
  text-decoration: none;
  display: flex;
	align-items: center;
	justify-content: center;
	background: #7082A8;
	border-radius: 50%;
	width: 29px;
	height: 29px;
	color: #2E3042;
  &:not(:last-child){
    margin-right: 7px;
  }
  @include  hover{
    opacity: 0.75;
  }
}