@font-face {
	font-family  : 'SF Pro Display';
	src          : url('../font/SFProDisplay/SFProDisplay-Black.eot');
	src          : url('../font/SFProDisplay/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
	url('../font/SFProDisplay/SFProDisplay-Black.woff2') format('woff2'),
	url('../font/SFProDisplay/SFProDisplay-Black.woff') format('woff'),
	url('../font/SFProDisplay/SFProDisplay-Black.ttf') format('truetype');
	font-weight  : 900;
	font-style   : normal;
	font-display : swap;
}

@font-face {
	font-family  : 'SF Pro Display';
	src          : url('../font/SFProDisplay/SFProDisplay-Bold.eot');
	src          : url('../font/SFProDisplay/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
	url('../font/SFProDisplay/SFProDisplay-Bold.woff2') format('woff2'),
	url('../font/SFProDisplay/SFProDisplay-Bold.woff') format('woff'),
	url('../font/SFProDisplay/SFProDisplay-Bold.ttf') format('truetype');
	font-weight  : 700;
	font-style   : normal;
	font-display : swap;
}

@font-face {
	font-family  : 'SF Pro Display';
	src          : url('../font/SFProDisplay/SFProDisplay-Medium.eot');
	src          : url('../font/SFProDisplay/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
	url('../font/SFProDisplay/SFProDisplay-Medium.woff2') format('woff2'),
	url('../font/SFProDisplay/SFProDisplay-Medium.woff') format('woff'),
	url('../font/SFProDisplay/SFProDisplay-Medium.ttf') format('truetype');
	font-weight  : 500;
	font-style   : normal;
	font-display : swap;
}

@font-face {
	font-family  : 'SF Pro Display';
	src          : url('../font/SFProDisplay/SFProDisplay-Regular.eot');
	src          : url('../font/SFProDisplay/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
	url('../font/SFProDisplay/SFProDisplay-Regular.woff2') format('woff2'),
	url('../font/SFProDisplay/SFProDisplay-Regular.woff') format('woff'),
	url('../font/SFProDisplay/SFProDisplay-Regular.ttf') format('truetype');
	font-weight  : 400;
	font-style   : normal;
	font-display : swap;
}

@font-face {
	font-family  : 'SF Pro Display';
	src          : url('../font/SFProDisplay/SFProDisplay-Semibold.eot');
	src          : url('../font/SFProDisplay/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
	url('../font/SFProDisplay/SFProDisplay-Semibold.woff2') format('woff2'),
	url('../font/SFProDisplay/SFProDisplay-Semibold.woff') format('woff'),
	url('../font/SFProDisplay/SFProDisplay-Semibold.ttf') format('truetype');
	font-weight  : 600;
	font-style   : normal;
	font-display : swap;
}

@font-face {
	font-family  : 'SF Pro Display';
	src          : url('../font/SFProDisplay/SFProDisplay-Light.eot');
	src          : url('../font/SFProDisplay/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
	url('../font/SFProDisplay/SFProDisplay-Light.woff2') format('woff2'),
	url('../font/SFProDisplay/SFProDisplay-Light.woff') format('woff'),
	url('../font/SFProDisplay/SFProDisplay-Light.ttf') format('truetype');
	font-weight  : 300;
	font-style   : normal;
	font-display : swap;
}

@font-face {
	font-family  : 'SF Pro Display';
	src          : url('../font/SFProDisplay/SFProDisplay-Lightitalic.eot');
	src          : url('../font/SFProDisplay/SFProDisplay-Lightitalic.eot?#iefix') format('embedded-opentype'),
	url('../font/SFProDisplay/SFProDisplay-Lightitalic.woff2') format('woff2'),
	url('../font/SFProDisplay/SFProDisplay-Lightitalic.woff') format('woff'),
	url('../font/SFProDisplay/SFProDisplay-Lightitalic.ttf') format('truetype');
	font-weight  : 300;
	font-style   : italic;
	font-display : swap;
}


// Own-import
@import "_var";
@import "_glob-modifiers";
@import "_grid.scss";
@import "_tags";
@import "_fonts";
//wp
@import "_wp.scss";

// Sections-import
@import "sections/_advantage.scss";
@import "sections/_contacts.scss";
@import "sections/_cover.scss";
@import "sections/_map.scss";
@import "sections/_products-box.scss";
@import "sections/_seo.scss";

// Components-import
@import "components/_2-col-block.scss";
@import "components/_2-col-small-block.scss";
@import "components/_2-col-with-image.block.scss";
@import "components/_alert.scss";
@import "components/_arrow-button.scss";
@import "components/_button.scss";
@import "components/_calculator.scss";
@import "components/_card.scss";
@import "components/_checkbox.scss";
@import "components/_contacts-block.scss";
@import "components/_contacts-shadow-block.scss";
@import "components/_cover-slide.scss";
@import "components/_cta-image.scss";
@import "components/_description.scss";
@import "components/_file-input.scss";
@import "components/_filter-buttons.scss";
@import "components/_first-form.scss";
@import "components/_first-slider.scss";
@import "components/_footer.scss";
@import "components/_footer-soc-list.scss";
@import "components/_form.scss";
@import "components/_gallery-images.scss";
@import "components/_go-to-start.scss";
@import "components/_header.scss";
@import "components/_hodden-buttons.scss";
@import "components/_horizontal-card.scss";
@import "components/_icon-cards-list.scss";
@import "components/_image-card.scss";
@import "components/_input.scss";
@import "components/_input-range.scss";
@import "components/_link-icon.scss";
@import "components/_logo.scss";
@import "components/_main.scss";
@import "components/_manager-block.scss";
@import "components/_menu.scss";
@import "components/_mob-menu-trigger.scss";
@import "components/_mobile-menu.scss";
@import "components/_modal.scss";
@import "components/_multilang.scss";
@import "components/_order-fast-cart-trigger.scss";
@import "components/_page.scss";
@import "components/_pagination.scss";
@import "components/_phone-data.scss";
@import "components/_phone-mail.scss";
@import "components/_preloader.scss";
@import "components/_product.scss";
@import "components/_radiobutton.scss";
@import "components/_review.scss";
@import "components/_sale-block.scss";
@import "components/_scrollbar.scss";
@import "components/_section.scss";
@import "components/_section-title-and-buttons.scss";
@import "components/_select.scss";
@import "components/_separator.scss";
@import "components/_sidebar.scss";
@import "components/_sidebar-trigger.scss";
@import "components/_sign-up-for-newsletter.scss";
@import "components/_small-title.scss";
@import "components/_soc-link.scss";
@import "components/_submenu.scss";
@import "components/_subtitle.scss";
@import "components/_table.scss";
@import "components/_table-summary.scss";
@import "components/_table-tag.scss";
@import "components/_tabs.scss";
@import "components/_text-and-image.scss";
@import "components/_text-block.scss";
@import "components/_textarea.scss";
@import "components/_time-filter.scss";
@import "components/_title.scss";
@import "components/_vacancies-block.scss";
@import "components/_video.scss";

//@import "components/**/*.scss";
@import "_additional-styles.scss";
@import "_single-prices-page.scss";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $firstFont;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
    text-decoration: none;
}

a:active {
    color: unset;
}

.body_overfloy-hidden {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

@media (hover: hover) {
     ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: rgba(60, 112, 222, 0.24);
    }
     ::-webkit-scrollbar-thumb {
        background: $orangeColor;
        border-radius: 2px;
    }
}

::selection {
    background-color: $darkColor;
    color: $lightColor;
}

[data-fancybox] {
    cursor: zoom-in;
}

.tr-td-2-white-space-nowrap{
  & tr {
    & td{

      &:nth-child(2){
        white-space: nowrap;
        padding-left: 6px;
      }
    }
  }
}
.tr-td-3-white-space-nowrap{
  & tr {
    & td{
      &:nth-child(3){
        white-space: nowrap;
        padding-left: 6px;
      }
    }
  }
}


@import "_vacancy_page.scss";
@import "_prices_page.scss";