.b-map {}

.b-map__inner {
    position: relative;
    width: 100%;
    padding-bottom: 28.65%;
    @include mobile {
        padding-bottom: 44%;
    }
}

.b-map__inner iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}